<template>
  <div class="express">
    <div class="label">快递：</div>
    <div class="item_wrap">
      <div
        class="title"
        :class="{ activeTitle: item === tag }"
        v-for="item in addressTags"
        :key="item"
        @click="selectTag(item)"
      >
        {{ item }}
      </div>
    </div>
    <div class="label">仓储：</div>
    <div class="item_wrap">
      <div
        class="express_item"
        :class="{ active: item.uid === modelValue }"
        v-for="item in addressList"
        :key="item.uid"
        @click="select(item)"
      >
        {{ item.name }}
      </div>
    </div>
    <div style="color: red; display: flex; font-size: 16px">
      <div style="width: 90px">仓库说明：</div>
      <div style="flex: 1; white-space: pre-wrap">{{ note || "-" }}</div>
    </div>
  </div>
</template>
<script>
export default {
  model: {
    prop: "modelValue",
    event: "select"
  },
  props: {
    modelValue: String,
    addressListData: Array,
    firstTypeSelected: Boolean // 选择的平台
  },
  watch: {
    modelValue() {
      const find = this.addressList.find(val => val.uid === this.modelValue);
      if (!find) return "-";
      this.note = find.time;
    }
  },
  computed: {
    // note() {
    //   const find = this.addressList.find(val => val.uid === this.value);
    //   console.log("[this.value] ---> ", this.value);
    //   console.log("[find] ---> ", find);
    //   if (!find) return "-";
    //   return find.time;
    // },
    addressTags() {
      const tagObj = {};
      this.addressListData.forEach(val => {
        const { waybillCompany } = val;
        if (waybillCompany && !tagObj[waybillCompany]) {
          tagObj[waybillCompany] = 1;
        }
      });
      return ["全部", ...Object.keys(tagObj)];
    },
    addressList() {
      return this.tag === "全部"
        ? this.addressListData
        : this.addressListData.filter(val => val.waybillCompany === this.tag);
    }
  },
  data() {
    return {
      value: 0,
      tag: "全部",
      note: ""
    };
  },
  methods: {
    select(item) {
      const { uid, time } = item;
      if (!this.firstTypeSelected) return this.$message.warning("请先选择平台");
      this.note = time || "-";
      this.value = uid;
      this.$emit("select", uid);
    },
    selectTag(tag) {
      if (!this.firstTypeSelected) return this.$message.warning("请先选择平台");
      this.tag = tag;
      this.select(this.addressList[0]);
    }
  }
};
</script>
<style lang="less" scoped>
.express {
  .label {
    font-size: 16px;
  }
  .item_wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;

    .express_item,
    .title {
      text-align: center;
      cursor: pointer;
      border: 1px solid rgba(217, 217, 217, 1);
      border-radius: 4px;
      padding: 6px 8px;
      color: rgba(0, 0, 0, 0.65);
      font-size: 16px;
      margin-right: 24px;
      white-space: nowrap; /* 防止文本换行 */
      overflow: hidden; /* 超出部分隐藏 */
      text-overflow: ellipsis; /* 显示省略号 */

      &:hover {
        border: 1px solid #1890ff;
        box-shadow: 0 0 1px #1890ff;
      }
    }
    .express_item {
      width: 230px;
    }
    .title {
      width: 120px;
      &:hover {
        border: 1px solid rgba(24, 144, 255, 0.3);
        box-shadow: 0 0 1px rgba(24, 144, 255, 0.3);
        background: rgba(24, 144, 255, 0.3);
        color: #fff;
      }
    }

    .active {
      border: 1px solid #1890ff;
      box-shadow: 0 0 1px #1890ff;
    }

    .activeTitle {
      border: 1px solid rgba(24, 144, 255, 0.3);
      box-shadow: 0 0 1px #1890ff;
      background: rgba(24, 144, 255, 0.3);
      color: #fff;
    }
  }
}
</style>
