import { render, staticRenderFns } from "./notSendModal.vue?vue&type=template&id=4650c26c&scoped=true&"
import script from "./notSendModal.vue?vue&type=script&lang=js&"
export * from "./notSendModal.vue?vue&type=script&lang=js&"
import style0 from "./notSendModal.vue?vue&type=style&index=0&id=4650c26c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4650c26c",
  null
  
)

export default component.exports