<template>
  <page-view :title="title">
    <div class="purchase_body">
      <!-- 头部 -->
      <div
        class="purchase_header"
        v-if="topAds && topAds.picture && topAds.enable"
        @click="topLink"
      >
        <img :src="topAds.picture" style="object-fit: cover" />
      </div>
      <!-- 第一步 -->
      <div class="step">
        <p class="stepTitle">Step1：选择数据来源平台</p>
        <div class="step_body">
          <div
            v-for="item in firstType"
            :key="item.id"
            class="step_body_item"
            @click="selectFirstType(item)"
            :class="item.id == firstTypeId ? 'active' : null"
          >
            {{ item.title }}
          </div>
        </div>
      </div>
      <!-- 第二步 -->
      <div class="step">
        <p class="stepTitle">Step2：录入收件人信息</p>
        <div class="step_body">
          <!-- v-for="item in (isSdf && firstTypeId === 'TAOBAO') ? secondType.filter(val => val.id !== 1) : secondType" -->
          <div
            v-for="item in secondType"
            :key="item.id"
            class="step_body_item"
            @click="selectSecondType(item.id)"
            :class="item.id == secondTypeId ? 'active' : null"
          >
            {{ item.title }}
          </div>
        </div>
      </div>
      <div v-show="secondTypeSelected">
        <!-- 店铺绑定 -->
        <div
          class="selectWay"
          style="flex-direction: column;"
          v-show="secondTypeId == 1"
        >
          <div v-if="spinning" style="text-align: right;">
            <a-button type="danger" @click="cancelLoading()">取消拉取</a-button>
          </div>
          <a-spin :spinning="spinning" tip="正在拉取订单...">
            <div>
              <span>选择店铺：</span>
              <a-select
                v-model="storeId"
                style="width: 180px"
                @change="handleStoreChange"
              >
                <a-select-option
                  v-for="item in storeOrderList"
                  :key="item.id"
                  :value="item.id"
                  >{{ item.shopName }}</a-select-option
                >
              </a-select>
              <span
                style="margin-left: 16px; color: #1890ff; cursor: pointer"
                @click="
                  () => {
                    $router.push('/personal/storeBind');
                  }
                "
                >绑定店铺</span
              >
            </div>
            <!-- <div class="trag_list" v-show="firstTypeId !== 'DOUYIN'"> -->
            <div class="trag_list">
              <span>标记筛选：</span>
              <a-radio-group v-model="tragId" class="trag_group">
                <a-radio class="radioStyle" :value="0" :key="0">全部</a-radio>
                <a-radio class="radioStyle" :value="1" :key="1">
                  <div
                    v-if="['TAOBAO', 'DOUYIN'].includes(firstTypeId)"
                    class="tragicon1 tragtb1"
                  ></div>
                  <div v-else class="tragicon2 tragal1"></div>
                </a-radio>
                <a-radio class="radioStyle" :value="2" :key="2">
                  <div
                    v-if="['TAOBAO', 'DOUYIN'].includes(firstTypeId)"
                    class="tragicon1 tragtb2"
                  ></div>
                  <div v-else class="tragicon2 tragal4"></div>
                </a-radio>
                <a-radio class="radioStyle" :value="3" :key="3">
                  <div
                    v-if="['TAOBAO', 'DOUYIN'].includes(firstTypeId)"
                    class="tragicon1 tragtb3"
                  ></div>
                  <div v-else class="tragicon2 tragal3"></div>
                </a-radio>
                <a-radio class="radioStyle" :value="4" :key="4">
                  <div
                    v-if="['TAOBAO', 'DOUYIN'].includes(firstTypeId)"
                    class="tragicon1 tragtb4"
                  ></div>
                  <div v-else class="tragicon2 tragal2"></div>
                </a-radio>
                <a-radio
                  class="radioStyle"
                  :value="5"
                  :key="5"
                  v-if="['TAOBAO', 'DOUYIN'].includes(firstTypeId)"
                >
                  <div class="tragicon1 tragtb5"></div>
                </a-radio>
              </a-radio-group>
            </div>
            <div>
              <a-tabs default-active-key="1" @change="filterWay">
                <a-tab-pane key="订单编号" tab="按订单编号筛选">
                  <a-textarea
                    style="margin: 10px 0; width: 720px; height: 120px"
                    :placeholder="textareaPlaceholder"
                    :autoSize="{ minRows: 5 }"
                    v-model="userInputContent"
                    class="resize_none"
                  />
                </a-tab-pane>
                <a-tab-pane key="买家账号" tab="按买家账号筛选" force-render>
                  <a-textarea
                    style="margin: 10px 0; width: 720px; height: 120px"
                    :placeholder="textareaPlaceholder"
                    :autoSize="{ minRows: 5 }"
                    v-model="userInputContent"
                    class="resize_none"
                  />
                </a-tab-pane>
              </a-tabs>
              <div class="store_bind_sure">
                <span>
                  选择时间:
                  <a-range-picker
                    v-model="importTimeList"
                    show-time
                    @ok="importTimeChange"
                    format="YYYY-MM-DD HH:mm:ss"
                  />
                  <a-button
                    type="primary"
                    style="margin-left: 12px;"
                    @click="importTimeReset"
                    >重置</a-button
                  >
                </span>
                <a-button type="primary" @click="showStoreOrder"
                  >确认添加</a-button
                >
                <span
                  v-show="addressListData.length > 0"
                  class="nosend_btn"
                  @click="showNoSendModal"
                >
                  <a-icon type="environment" style="color: #f93244" />
                  点击查看停发区域</span
                >
              </div>
            </div>
          </a-spin>
        </div>
        <!-- 订单编号筛选 模板导入-->
        <div class="filter" v-show="showcheckInput">
          <span>按订单编号筛选</span>
          <a-textarea
            style="margin-bottom: 10px; margin-top: 10px"
            :placeholder="textareaPlaceholder"
            :autoSize="{ minRows: 5 }"
            v-model="userInputContent"
          />
          <a-button type="primary" @click="showFilterOrderModal"
            >确认筛选</a-button
          >
          <a-button @click="gobackHandle" style="margin-left: 12px"
            >重新上传</a-button
          >
        </div>
        <!-- 模板导入 -->
        <div class="selectWay" v-show="secondTypeId == 5 && showUpload">
          <div class="middleLeft">
            <div
              class="clearfix"
              :style="{
                marginBottom: '14px',
                position: 'relative',
                width: '384px',
                height: '176px'
              }"
            >
              <a-upload-dragger
                accept=".xls, .xlsx, .csv, .xlsm, .xltx"
                :fileList="fileList"
                :remove="handleRemove"
                :beforeUpload="beforeUpload"
                @change="handleChangeUploadGuanFang"
              >
                <p class="ant-upload-drag-icon">
                  <a-icon type="inbox" />
                </p>
                <p class="ant-upload-text">
                  将文件拖拽到此处，或
                  <span style="color: #1890ff">点击上传</span>
                </p>
                <p class="ant-upload-hint">
                  仅支持CSV、XLS、XLSM、XLTX、XLSX文件
                </p>
              </a-upload-dragger>
              <div v-if="firstTypeId === 'TAOBAO'" style="height: 2px;"></div>
              <a-radio-group
                v-if="firstTypeId === 'TAOBAO'"
                v-model="tableFormat"
              >
                <a-radio-button value="tableNormal">
                  默认表格格式导入
                </a-radio-button>
                <a-radio-button value="tablePrivacy">
                  含隐私号表格格式导入
                </a-radio-button>
              </a-radio-group>
            </div>
          </div>
          <div class="middleRight type5" style="width: 488px">
            <p>注意事项:</p>
            <p>
              1.从官方平台导出的订单模板，直接上传即可，
              <span v-if="firstTypeId === 'TAOBAO'">
                <span v-if="tableFormat !== 'tablePrivacy'" style="color:red"
                  >淘宝模板上传后请检查订单号[第1列]手机号[第19列]地址[第16列]收货人姓名[第15列]是否正确</span
                >
                <span v-else style="color:red"
                  >淘宝模板上传后请检查订单号[第1列]手机号[第17列]地址[第14列]收货人姓名[第13列]是否正确</span
                >
              </span>
              <span v-else> </span>
            </p>
            <p>
              2.可选择下载本平台指定模板格式上传，点击
              <a
                :style="{ color: '#1890ff', textDecoration: 'none' }"
                href="//static.wodh.cn/lpw/%E5%9C%B0%E5%9D%80%E6%A8%A1%E6%9D%BF.xlsx"
                >下载模板</a
              >
            </p>
            <p>3.仅支持CSV、XLS、XLSM、XLTX、XLSX文件</p>
            <p v-show="addressListData.length > 0">
              4.为不影响您的正常发货，请留意各省市快递停发区域。
              <span class="nosend_btn" @click="showNoSendModal"
                >点击查看停发区域</span
              >
            </p>
            <p v-if="firstTypeId === 'PDD'" style="color: #f93244">
              5.拼多多下单虚拟手机需要更改。
            </p>
          </div>
        </div>
        <!-- 手工填写 -->
        <div class="selectWay" v-show="secondTypeId == 4">
          <a-tabs
            v-model:value="adrTabsKey"
            default-active-key="1"
            @change="selectModal"
          >
            <a-tab-pane key="1" tab="地址格式一">
              <div style="display: flex">
                <div style="width: 720px; margin-right: 24px">
                  <a-textarea
                    :style="{ marginBottom: '14px' }"
                    :placeholder="textareaPlaceholder2"
                    :autoSize="{ minRows: 5 }"
                    v-model="modalOneContent"
                  />
                  <a-button type="primary" @click="handleModalOneOK"
                    >确认添加</a-button
                  >
                </div>
                <div class="middleRight modal2">
                  <p>注意事项:</p>
                  <p>
                    1.订单编号，姓名，电话，地址
                    按顺序填写，中间用逗号分隔，一次可以输入多个收件人信息，一个收件人信息一行输入。
                  </p>
                  <p>
                    2.淘宝/天猫、京东、拼多多等平台，如无订单编号可随机填写，或不填写。
                  </p>
                  <p>
                    3.如遇东莞市、三沙市、儋州市、嘉峪关市等没有区的城市，区域可设置为“其他区”
                  </p>
                  <p v-show="addressListData.length > 0">
                    4.为不影响您的正常发货，请留意各省市快递停发区域。
                    <span class="nosend_btn" @click="showNoSendModal"
                      >点击查看停发区域</span
                    >
                  </p>
                  <p style="color: #f93244; font-size: 18px">
                    5.如要确保地址完全一致，不被系统自动拆分，请使用地址格式二，<a
                      @click="adrTabsKey = '2'"
                      >点此快速切换</a
                    >
                  </p>
                  <p v-if="firstTypeId === 'PDD'" style="color: #f93244">
                    6.拼多多下单虚拟手机需要更改。
                  </p>
                  <br />
                  <p>示例:</p>
                  <p>
                    811000000000，张三，13888888888，湖北省 武汉市 江汉区
                    解放大道392号
                  </p>
                  <p>虚拟号格式填写（电话-分机号）：</p>
                  <p v-if="['DOUYIN', 'JD'].includes(firstTypeId)">
                    811000000000，张三，13888888888，湖北省 武汉市 江汉区
                    解放大道392号（13888888888-1234）
                  </p>
                  <p v-else>
                    811000000000，张三，13888888888-1234，湖北省 武汉市 江汉区
                    解放大道392号
                  </p>
                </div>
              </div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="地址格式二" force-render>
              <div style="display: flex">
                <div style="margin-right: 24px">
                  <div>
                    <a-input
                      style="width: 150px; margin-right: 8px; margin-bottom: 8px"
                      placeholder="订单编号(可不填)"
                      v-model.trim="modalTwoParams.orderNo"
                    />
                    <a-input
                      style="width: 100px; margin-right: 8px; margin-bottom: 8px"
                      placeholder="姓名"
                      v-model.trim="modalTwoParams.name"
                    />
                    <a-input
                      style="width: 80px; margin-right: 8px; margin-bottom: 8px"
                      placeholder="手机号"
                      v-model.trim="modalTwoParams.mobile"
                    />
                    <a-input
                      style="width: 80px; margin-right: 8px; margin-bottom: 8px"
                      placeholder="输入省"
                      v-model.trim="modalTwoParams.province"
                    />
                    <a-input
                      style="width: 80px; margin-right: 8px; margin-bottom: 8px"
                      placeholder="输入市"
                      v-model.trim="modalTwoParams.city"
                    />
                    <a-input
                      style="width: 80px; margin-right: 8px; margin-bottom: 8px"
                      placeholder="输入县"
                      v-model.trim="modalTwoParams.county"
                    />
                    <a-input
                      style="width: 180px; margin-right: 8px; margin-bottom: 8px"
                      placeholder="详细地址，街道，楼栋等"
                      v-model.trim="modalTwoParams.address"
                    />
                  </div>
                  <a-button
                    type="primary"
                    @click="handleModalTwoOk"
                    style="margin-top: 16px"
                    >确认添加</a-button
                  >
                </div>
                <div class="middleRight modal2">
                  <p>注意事项:</p>
                  <p>
                    1.订单编号，姓名，电话，地址
                    按顺序填写，中间用逗号分隔，一次可以输入多个收件人信息，一个收件人信息一行输入。
                  </p>
                  <p>
                    2.淘宝/天猫、京东、拼多多等平台，如无订单编号可随机填写，或不填写。
                  </p>
                  <p>
                    3.如遇东莞市、三沙市、儋州市、嘉峪关市等没有区的城市，区域可设置为“其他区”
                  </p>
                  <p v-show="addressListData.length > 0">
                    4.为不影响您的正常发货，请留意各省市快递停发区域。
                    <span class="nosend_btn" @click="showNoSendModal"
                      >点击查看停发区域</span
                    >
                  </p>
                  <p v-if="firstTypeId === 'PDD'" style="color: #f93244">
                    5.拼多多下单虚拟手机需要更改。
                  </p>
                  <br />
                  <p>示例:</p>
                  <p>
                    811000000000，张三，13888888888，湖北省 武汉市 江汉区
                    解放大道392号
                  </p>
                  <p>
                    811000000000，张三，13888888888，湖北省 武汉市 江汉区
                    解放大道392号（13888888888-1234）
                  </p>
                </div>
              </div>
            </a-tab-pane>
          </a-tabs>
        </div>
        <!-- 插件下单 -->
        <div class="selectWay" v-show="secondTypeId == 6">
          <div style="font-size:16px">
            <div class="plugin_state" v-if="!hasPlugin">
              未检测到插件，请刷新当前页面或检查浏览器插件安装情况
              <a style="margin:0 12px;" href="//static.wodh.cn/lpw/app2.rar"
                >360浏览器下载</a
              ><a href="//static.wodh.cn/lpw/app.zip">谷歌浏览器下载</a>
            </div>
            <div v-if="hasPlugin && !loginStatus">
              当前浏览器未登录店铺
              <a @click="loginPlugin" style="color:#1890ff;" href="#">
                点击登录
              </a>
              <a @click="refreshlogin" href="#">
                点击刷新
              </a>
            </div>
            <div v-if="loginStatus" class="plugin_wrapper">
              <div class="plugin_info">
                当前检测到店铺：{{
                  pluginUser.taobao.name
                }}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 已获取待发货订单{{
                  tbOrderList.length
                }}条
                <div class="pro_wrapper">
                  <a-progress
                    size="small"
                    :percent="
                      +parseFloat(
                        (tbOrderList.length / orderTotal) * 100
                      ).toFixed(2)
                    "
                  />
                </div>
                共{{ orderTotal }}条
              </div>

              <a-button
                type="link"
                :loading="orderDisabled"
                @click="refreshpluginorder"
                size="large"
              >
                点击刷新
              </a-button>

              <a-button
                type="link"
                @click="getOrderRest"
                size="large"
                v-if="needSlide"
              >
                已完成滑块继续获取订单
              </a-button>
            </div>
            <a-tabs default-active-key="1" @change="filterWay">
              <a-tab-pane key="订单编号" tab="按订单编号筛选">
                <a-textarea
                  style="margin: 10px 0; width: 720px; height: 120px"
                  :placeholder="textareaPlaceholder"
                  :autoSize="{ minRows: 5 }"
                  v-model="userInputContent"
                  class="resize_none"
                />
              </a-tab-pane>
              <a-tab-pane key="买家账号" tab="按买家账号筛选" force-render>
                <a-textarea
                  style="margin: 10px 0; width: 720px; height: 120px"
                  :placeholder="textareaPlaceholder"
                  :autoSize="{ minRows: 5 }"
                  v-model="userInputContent"
                  class="resize_none"
                />
              </a-tab-pane>
            </a-tabs>
            <div class="store_bind_sure">
              <a-button type="primary" @click="showPluginOrder"
                >确认添加</a-button
              >
              <div>
                <span class="plugin_doc" @click="showPluginDoc"
                  >插件使用教程</span
                >
                <span
                  v-show="addressListData.length > 0"
                  class="nosend_btn"
                  @click="showNoSendModal"
                >
                  <a-icon type="environment" style="color: #f93244" />
                  点击查看停发区域</span
                >
              </div>
            </div>
            <div class="plugin_pro">如插件下单出现问题，请联系在线客服</div>
          </div>
        </div>
        <!-- 客户端下单 -->
        <div class="selectWay" v-show="secondTypeId == 7">
          <div style="font-size:16px">
            <div class="plugin_state" v-if="!hasClient">
              未检测到客户端，本地导入需下载客户端程序，登录店铺即可免费查询订单，自动发货
              <!--客户端链接-->
              <a
                style="margin:0 12px;"
                href="//static.wodh.cn/lpw/client/%E7%A4%BC%E5%93%81%E4%BB%A3%E5%8F%91.exe"
                >点击下载</a
              >
            </div>
            <!--            <p>天猫店铺不稳定<span style="color: red;">(请登录店铺后，关闭手机号隐私保护)</span></p>-->
            <div v-if="hasClient && !clientLogin.taobao.login">
              <p>当前客户端未登录店铺</p>
              <a
                @click="loginClient('TAOBAO', true)"
                style="color:#1890ff;"
                href="#"
              >
                点击登录
              </a>
            </div>
            <div v-if="clientLogin.taobao.login" class="plugin_wrapper">
              <div class="plugin_info">
                当前检测到店铺：{{
                  clientLogin.taobao.name
                }}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 已获取待发货订单{{
                  clientOrderList.length
                }}条
                <div class="pro_wrapper">
                  <a-progress
                    size="small"
                    :percent="
                      +parseFloat(
                        (clientOrderList.length /
                          clientLogin.taobao.orderTotal) *
                          100
                      ).toFixed(2)
                    "
                  />
                </div>
                共{{ clientLogin.taobao.orderTotal }}条
              </div>
              <a-button
                type="link"
                :loading="clientOrderListDisabled"
                @click="loginClient('TAOBAO', true)"
                href="#"
              >
                重新登录
              </a-button>

              <a-button
                type="link"
                :loading="clientOrderListDisabled"
                @click="loginClient('TAOBAO', false)"
                size="large"
              >
                点击刷新
              </a-button>

              <a-button
                type="link"
                @click="getOrderRest"
                size="large"
                v-if="needSlide"
              >
                已完成滑块继续获取订单
              </a-button>
            </div>
            <a-tabs default-active-key="1" @change="filterWay">
              <a-tab-pane key="订单编号" tab="按订单编号筛选">
                <a-textarea
                  style="margin: 10px 0; width: 720px; height: 120px"
                  :placeholder="textareaPlaceholder"
                  :autoSize="{ minRows: 5 }"
                  v-model="userInputContent"
                  class="resize_none"
                />
              </a-tab-pane>
              <a-tab-pane key="买家账号" tab="按买家账号筛选" force-render>
                <a-textarea
                  style="margin: 10px 0; width: 720px; height: 120px"
                  :placeholder="textareaPlaceholder"
                  :autoSize="{ minRows: 5 }"
                  v-model="userInputContent"
                  class="resize_none"
                />
              </a-tab-pane>
            </a-tabs>
            <div class="store_bind_sure">
              <a-button type="primary" @click="showClientOrder"
                >确认添加</a-button
              >
              <div>
                <!--                <span class="plugin_doc" @click="showPluginDoc">本地导入使用教程</span>-->
                <span
                  v-show="addressListData.length > 0"
                  class="nosend_btn"
                  @click="showNoSendModal"
                >
                  <a-icon type="environment" style="color: #f93244" />
                  点击查看停发区域</span
                >
              </div>
            </div>
            <div class="plugin_pro">如本地下单出现问题，请联系在线客服</div>
          </div>
        </div>
        <div class="check_wrapper" v-show="checkGoodsInfo">
          <div v-if="errorOutputs.length > 0" class="error_info">
            没有筛选到的订单：
            <span v-for="(error, index) in errorOutputs" :key="index"
              >{{ error }}；</span
            >
          </div>
          <!-- 筛选后的列表 -->
          <check-goods-info
            v-if="checkGoodsInfo"
            :dataSource="dataSource"
            @deleteData="deleteData"
            @changeMobile="changeMobile"
            @gobackHandle="gobackHandle"
            @gotopHandle="gotopHandle"
            @deleteRows="deleteRows"
            :firstTypeId="firstTypeId"
            :secondTypeId="secondTypeId"
          />
        </div>
      </div>
      <div class="step" style="margin-top: 24px" id="step4_div">
        <p class="stepTitle">
          Step3：选择仓储&nbsp;&nbsp;
        </p>
        <Express
          v-model="addressUid"
          :addressListData="addressListData"
          :firstTypeSelected="firstTypeSelected"
          @select="onAddressChange"
        />
      </div>
      <!-- 第三步 -->
      <div class="step" style="margin-top: 24px" id="step3_div">
        <p class="stepTitle">
          Step4：选择礼品&nbsp;&nbsp;
          <!-- <a-tooltip title="收货姓名、电话和地址中包含仓库禁发关键词则会禁止下单">
            <a style="font-size: 12px; color: red" @click="showNoSendKey">点击查看仓库禁发关键词</a>
          </a-tooltip> -->
        </p>
        <div class="step_body3">
          <!-- 选择礼品 -->
          <div class="gift_container">
            <!-- <div class="address_list">
              <div style="height:20px;width:31.7px">仓储:</div>
              <ul>
                <li
                  v-for="item in addressListData"
                  :key="item.uid"
                  :class="addressUid == item.uid ? 'active' : ''"
                  @click="onAddressChange(item.uid)"
                >
                  {{ item.name }}
                </li>
              </ul>
            </div> -->
            <div class="sort_list">
              <span>排序:</span>
              <ul v-if="goodsList.length > 0">
                <li
                  :class="sortway == 'no' ? 'active' : ''"
                  @click="changeSortWay('no')"
                >
                  默认
                </li>
                <li
                  :class="sortway == 'price' ? 'active' : ''"
                  @click="changeSortWay('price')"
                >
                  价格从低到高
                </li>
                <li
                  :class="sortway == 'sales' ? 'active' : ''"
                  @click="changeSortWay('sales')"
                >
                  销量从低到高
                </li>
                <li
                  :class="sortway == 'weight' ? 'active' : ''"
                  @click="changeSortWay('weight')"
                >
                  重量从轻到重
                </li>
              </ul>
            </div>
            <div style="margin-bottom: 12px;">
              <span style="margin-right: 12px;">查询:</span>
              <a-space>
                <a-input
                  v-model="searchGiftValue"
                  placeholder="请输入礼品名称"
                />
                <a-button @click="searchGift" type="primary">搜索</a-button>
                <a-button @click="resetSearch" type="default">重置</a-button>
              </a-space>
            </div>
            <ul v-if="goodsList.length > 0" class="goodsList">
              <li
                v-for="(item, index) in goodsList"
                :key="index"
                :class="goodSelectedId == item.id ? 'active' : ''"
                @mouseover="handleMouseover(index)"
                @mouseout="handleMouseout(index)"
              >
                <div class="poster">
                  <img :src="item.picture" alt />
                </div>
                <div class="price">￥{{ item.price }}</div>
                <div class="text">
                  <div class="title">{{ item.name }}</div>
                  <div class="weight">{{ Number(item.weight) * 1000 }}g</div>
                </div>
                <div
                  class="btn"
                  v-show="item.inWrap"
                  @click="handleSelect(item)"
                >
                  <a>确认礼品</a>
                </div>
              </li>
            </ul>
            <!-- 没有礼品的情况 -->
            <div v-else>
              <a-empty :image="simpleImage" />
            </div>
            <a-pagination
              v-if="goodsList.length > 0"
              showSizeChanger
              @change="pageChange"
              @showSizeChange="onShowSizeChange"
              :defaultCurrent="1"
              :defaultPageSize="page.pageSize"
              :total="page.total"
              :pageSizeOptions="['10', '18', '50', '100']"
            />
          </div>
          <!-- 选择后 -->
          <div class="gift_list_output">
            <a-table
              :style="{ marginBottom: '14px' }"
              :columns="columns"
              :dataSource="giftLists"
              :pagination="false"
              :rowKey="record => record.id"
            >
              <template slot="picture" slot-scope="text, record">
                <div style="display:flex;align-items: center">
                  <img
                    :style="{ display: 'block', width: '48px', heigth: '48px' }"
                    :src="record.picture"
                    alt
                  />
                  <span :style="{ marginLeft: '10px' }">{{ record.name }}</span>
                </div>
              </template>
            </a-table>
            <div class="order_info">
              <span>备注信息：</span>
              <a-input
                v-model="comment"
                style="width: 280px"
                placeholder="请输入需要备注的信息"
              />
            </div>
            <div class="order_info">
              <span :style="{ marginRigth: '10px' }">运送方式：</span>
              <a-select
                style="width: 240px; margin-right: 8px"
                placeholder="请选择快递"
                v-model="payParams.logisticsId"
                @change="handleSelectDelivery"
              >
                <a-select-option
                  v-for="item in deliveryList"
                  :key="item.id"
                  :value="item.id"
                  :disabled="!item.enabled"
                  >{{ item.name + " / " + item.price + "元" }}</a-select-option
                >
              </a-select>
            </div>
            <div class="order_info">
              收件人数：
              <span>{{ selectedOrders.length || dataSource.length }}</span>
            </div>
            <div class="order_info">
              礼品总额：
              <span style="color: #f93244"
                >￥{{ goodsSumPrice ? goodsSumPrice : "0" }}</span
              >
            </div>
            <div class="order_info">
              礼品运费：
              <span>￥{{ deliverySumPrice ? deliverySumPrice : "0" }}</span>
            </div>
            <div
              class="order_info"
              v-if="
                giftLists.length > 0 &&
                  dataSource.length > 0 &&
                  allPremium.count > 0
              "
            >
              运费溢价：
              <span style="margin-bottom: 10px; margin-right: 16px"
                >￥{{ allPremium.price }}</span
              >
              <span>
                <span
                  style="margin-right: 16px"
                  v-for="(premium, index) in premiums"
                  :key="index"
                  >{{ `${premium.province}${premium.price}元` }}</span
                >
                <span style="color: red">共有{{ allPremium.count }}件溢价</span>
              </span>
            </div>
            <div>
              应付金额：
              <span style="color: #f93244">
                ￥{{
                  parseFloat(
                    parseFloat(giftSumPrice) + parseFloat(allPremium.price)
                  ).toFixed(2)
                }}
              </span>
            </div>
            <div class="order_btn" @click="handleOrderInsert">
              提交订单 ￥{{
                parseFloat(
                  parseFloat(giftSumPrice) + parseFloat(allPremium.price)
                ).toFixed(2)
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 右侧广告 -->
    <div
      class="purchase_banner"
      v-if="rightAds && bannerShow && rightAds.picture && rightAds.enable"
    >
      <div class="close_father" @click="rightLink">
        <img :src="rightAds.picture" alt="" />
        <div
          class="close_btn"
          @click="
            () => {
              bannerShow = false;
            }
          "
        ></div>
      </div>
    </div>

    <!-- 支付 -->
    <PayWay
      ref="payWay"
      @showOrder="haspay"
      @gobackTopurchase="gobackTopurchase"
    />
    <!-- 订单筛选 -->
    <filter-order ref="filterOrder" @showCheckGoodsInfo="showCheckGoodsInfo" />
    <!-- 淘宝密码 -->
    <a-modal v-model="showPasswordInput" title="输入文件密码" @ok="handleOk">
      <span>导入淘宝/天猫官方的订单模板，需要输入文件密码</span>
      <a-input
        placeholder="请输入密码"
        v-model="taobaoPassword"
        style="margin-top: 16px"
      />
    </a-modal>
    <!-- 清除停发 -->
    <a-modal v-model="deleteNoSendModal" :closable="false" width="400px">
      <div class="nosend_warning">
        收货地址共有{{ noSendAddress.length }}条数据停发，是否一键清除？
      </div>
      <template slot="footer">
        <a-button
          key="back"
          @click="
            () => {
              deleteNoSendModal = false;
            }
          "
        >
          取消
        </a-button>
        <a-button key="submit" type="primary" @click="handleDeleteAllNotSend">
          一键清除
        </a-button>
        <a-button key="download" @click="downLoadNoSend">
          下载停发地址
        </a-button>
      </template>
    </a-modal>
    <!-- 停发列表 -->
    <notSendModal ref="notSendModal" />
    <!-- 禁用关键词 -->
    <!-- <noSendKeyModalC ref="notSendKeyModal" /> -->
  </page-view>
</template>

<script>
import XLSX from "xlsx";
import Papa from "papaparse";
import {
  addressLogisticsListByUser,
  orderInsert,
  premiumList,
  getExcelPassword,
  sipUser,
  addressList,
  goodsListAll,
  storeList,
  storeOrders,
  goodsList,
  extensionListGetUser,
  x5CookieAddress,
  x5CookieHeader,
  x5OrderList,
  addressAnalysis
} from "@/api";
import { setAddressRsultJs } from "@/utils/addressUtil";
import { PageView } from "@/layouts";
import FilterOrder from "./modal/filterOrder";
import notSendModal from "./modal/notSendModal";
// import noSendKeyModalC from './modal/noSendKey.vue'
import CheckGoodsInfo from "@/components/purchase/checkGoodsInfo";
import Express from "./express.vue";
import PayWay from "@/components/PayWay";
import { Empty } from "ant-design-vue";
import { export_json_to_excel } from "@/utils/excel/Export2Excel";
import { arrNewSet } from "@/utils/util";
import { sendMessageSync, sendMessage } from "@/client/message";
import { requestClientBase } from "@/client/requestClientBase";
import Vue from "vue";
import { ACCESS_TOKEN, CLIENT_LOGIN } from "@/store/mutation-types";
import { setDocumentTitle } from "@/utils/domUtil"; // TODO dyClient 利用本地客户端请求
import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");

const qs = require("qs");
const dyFlag = {
  0: 0,
  1: 5,
  2: 4,
  3: 3,
  4: 2,
  5: 1
};
const columns = [
  {
    title: "礼品信息",
    dataIndex: "picture",
    scopedSlots: { customRender: "picture" }
  },

  {
    title: "单价",
    dataIndex: "price"
  },
  {
    title: "数量",
    dataIndex: "operation",
    customRender: text => "1"
  },
  {
    title: "重量（g）",
    dataIndex: "weight",
    customRender: text => text * 1000 + "g"
  },
  {
    title: "发货仓库",
    dataIndex: "warehouse"
  },
  {
    title: "小计",
    dataIndex: "sum"
  }
];

export default {
  components: {
    PageView,
    FilterOrder,
    CheckGoodsInfo,
    PayWay,
    notSendModal,
    // noSendKeyModalC
    Express
  },
  data() {
    return {
      importTimeList: [
        moment()
          .add(-3, "d")
          .startOf("day"),
        moment().endOf("day")
      ],
      // isSdf: /sudf.cn/.test(location.href),
      adrTabsKey: "1",
      tableFormat: "tableNormal",
      selectedOrders: [],
      bannerShow: true,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE, // 空状态
      spinning: false, // 拉取订单
      storeId: "", // 店铺id
      shopName: "", // 店铺名称
      tragId: 0, // 店铺标记
      storeOrderList: [], // 店铺列表
      showUpload: false, // 模板导入
      showcheckInput: false, // 筛选
      baseData: baseData,
      sortway: "no", // 礼品排序
      goodsList: [], // 所有的礼品列表
      goodsList2: [],
      goodSelectedId: "", // 礼品id
      addressUid: "", // 仓库id
      addressListData: [], // 仓库列表
      inWrap: false,
      page: { pageNum: 1, pageSize: 18, total: 0 },
      user: null, // 用户
      columns,
      comment: "", // 备注
      premiums: [], // 溢价信息
      notSend: [], // 暂停发货信息
      noSendAddress: [], // 用户收货地址中停发的
      deleteNoSendModal: false,
      allNoSend: 0, // 筛选出来的暂停发货的地址数量
      allPremium: { count: 0, price: 0 },
      // 一二步的选择---
      firstType: [
        {
          id: "TAOBAO",
          title: "淘宝/天猫",
          type: "TAOBAO"
        },
        {
          id: "ALIBABA",
          title: "阿里巴巴",
          type: "ALIBABA"
        },
        {
          id: "JD",
          title: "京东",
          type: "JD"
        },
        {
          id: "PDD",
          title: "拼多多",
          type: "PDD"
        },
        {
          id: "DOUYIN",
          title: "抖音",
          type: "DOUYIN"
        },
        {
          id: "XHS",
          title: "小红书",
          type: "XHS"
        },
        {
          id: "OTHER",
          title: "其他",
          type: "OTHER"
        }
      ],
      secondType: [
        {
          id: 5,
          img: "",
          title: "模板导入",
          active: false
        },
        {
          id: 1,
          img: "",
          title: "店铺导入",
          active: false
        },
        {
          id: 4,
          img: "",
          title: "手工填写",
          active: false
        },
        // {
        //   id: 6,
        //   img: '',
        //   title: '插件下单(内测)',
        //   active: false
        // },
        {
          id: 7,
          img: "",
          title: "本地导入(内测)",
          active: false
        }
      ],
      cacheSecondType: [],
      firstTypeId: "",
      firstTypeSelected: false,
      secondTypeSelected: false,
      secondTypeId: "",
      // --
      dataSource: [], // 列表数据
      giftLists: [], // 选择后的礼品
      giftInfo: { count: 1 },
      fileList: [],
      secondType4Modal: 1,
      checkGoodsInfo: false,
      outputs: [],
      errorOutputs: [], // 未筛选到的
      textareaPlaceholder: "示例:\n买家账号1\n买家账号2\n买家账号3",
      textareaPlaceholder2:
        "订单编号(可不填)，姓名，电话，地址（中间用逗号分隔，回车键换行，一行一条~）\n订单编号(可不填)，姓名，电话，地址\n订单编号(可不填)，姓名，电话，地址\n订单编号(可不填)，姓名，电话，地址",
      userInputContent: "",
      currentFilterWay: 0, // 0为买家账号 1为订单编号
      deliveryList: [],
      modalOneContent: "",
      modalTwoParams: {},
      modalTwoData: [],
      payParams: {},
      uploadParams: {},
      record: {},
      selectStoreName: [],
      showPasswordInput: false, // 淘宝密码
      taobaoPassword: "",
      taobaoFile: null,
      taobaoObj: null,
      uploadStatus: true, // 密码正确与否
      rightAds: {},
      topAds: {},
      hasPlugin: false, // 插件状态检查
      loginStatus: false, // 插件店铺登录情况
      hasClient: false, // 客户端状态检查
      clientLogin: {
        // 客户端-店铺登录情况
        taobao: { login: false, id: "", name: "", cookie: "", orderTotal: 0 } // 淘宝
      },
      clientOrderList: [], // 客户端订单列表
      clientOrderListDisabled: false, // 客户端是否在获取订单列表
      tbOrderList: [],
      pluginUser: {
        taobao: { name: "", id: "" }
      },
      orderDisabled: false,
      needSlide: false,
      extShopId: "",
      orderFinish: false,
      orderTotal: 0,
      searchGiftValue: ""
    };
  },
  computed: {
    title() {
      return this.$route.meta.title;
    },
    // 快递总价
    deliverySumPrice() {
      const { giftInfo, dataSource, selectedOrders } = this;
      const price = Number(giftInfo.deliveryPrice);
      const count = selectedOrders.length || dataSource.length || 1;
      const result = price * count;
      if (isNaN(result)) {
        return null;
      }
      return result.toFixed(2);
    },
    goodsSumPrice() {
      const { giftInfo, dataSource, selectedOrders } = this;
      const price = Number(giftInfo.goodsPrice);
      const count = selectedOrders.length || dataSource.length || 1;
      const result = price * count;
      if (isNaN(result)) {
        return null;
      }
      return result.toFixed(2);
    },
    goodsWeigth() {
      const { giftInfo } = this;
      const weight = Number(giftInfo.weight);
      const count = Number(giftInfo.count);
      const result = weight * count;
      if (isNaN(result)) {
        return null;
      }
      return result.toFixed(2);
    },
    giftSumPrice() {
      const result = Number(this.deliverySumPrice) + Number(this.goodsSumPrice);
      if (isNaN(result)) {
        return null;
      }
      return result.toFixed(2);
    }
  },
  created() {
    // TODO 判断clientLogin是否存在
    if (window.client) {
      const clientLogin = Vue.ls.get(CLIENT_LOGIN);
      if (
        clientLogin &&
        clientLogin.taobao &&
        clientLogin.taobao.name &&
        clientLogin.taobao.id
      ) {
        this.clientLogin = clientLogin;
      }
    }
  },
  mounted() {
    // 获取用户
    this.getUserInfo();
    // 仓库列表
    this.getAddressListAll();
    // 所有的礼品列表
    this.handleGetGoodListFirst();
    // 广告
    this.hanleGetExtensionList();
    // 插件接受信息
    window.addEventListener("message", this.listener, false);

    if (window.client) {
      // 客户端更新
      const isUpdate = localStorage.getItem("open-update") || "0";
      if (isUpdate === "1") {
        this.$info({
          title: "发现软件更新",
          content: "点击确定重启更新软件",
          okText: "确定",
          onOk() {
            localStorage.setItem("open-update", "0");
            window.electron.ipcRenderer.send("close-client");
          }
        });
      }
    }
  },
  destroyed() {
    window.removeEventListener("message", this.listener);
  },
  methods: {
    // 店铺导入时间更改
    importTimeChange([start, end]) {
      const timeList = [moment(start), moment(end)];
      console.log("[timeList] ------> ", timeList);
      this.importTimeList = timeList;
    },
    // 店铺导入时间重置
    importTimeReset() {
      const startTime = moment()
        .add(-3, "d")
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ss");
      const endTime = moment()
        .endOf("day")
        .format("YYYY-MM-DD HH:mm:ss");
      this.importTimeList = [startTime, endTime];
    },
    // 取消 loading
    cancelLoading() {
      this.spinning = false;
    },
    loginPlugin() {
      window.open("https://login.taobao.com/member/login.jhtml");
    },
    refreshlogin() {
      console.log("刷新店铺");
      window.postMessage({ type: "get_user" }, "*");
    },
    refreshpluginorder() {
      console.log("刷新订单");
      this.orderDisabled = true;
      this.orderTotal = 0;
      this.orderFinish = false;
      this.tbOrderList = [];
      this.dataSource = [];
      window.postMessage({ type: "get_order" }, "*");
    },
    getOrderRest() {
      console.log("继续获取订单");
      this.needSlide = false;
      this.orderDisabled = true;
      window.postMessage({ type: "get_order_rest" }, "*");
    },
    listener(event) {
      const eventData = event.data || {};
      if (eventData.type) {
        if (eventData.type === "over-user") {
          this.overUser(eventData.user);
        } else if (eventData.type === "over-order") {
          this.overOrder(eventData);
        } else if (eventData.type === "over-order-part") {
          this.overOrderPart(eventData);
        } else if (eventData.type === "slider") {
          console.log("要滑块验证");
          this.overSlider();
        }
      }
    },
    // 获取插件登录店铺
    overUser(data) {
      console.log("获取店铺", data);
      this.pluginUser = data;
      if (this.pluginUser.taobao.name && this.pluginUser.taobao.id) {
        this.loginStatus = true;
        // 查淘宝订单
        this.order();
      } else {
        this.loginStatus = false;
      }
    },
    // 发起获取店铺订单请求
    order() {
      console.log("礼品网查询订单");
      this.orderDisabled = true;
      window.postMessage({ type: "get_order" }, "*");
    },
    // 接受店铺订单
    overOrder(data) {
      const arr = data.order.order_list;
      this.tbOrderList = [...this.tbOrderList, ...arr];
      // 订单去重
      const newobj = {};
      this.tbOrderList = this.tbOrderList.reduce((preVal, curVal) => {
        newobj[curVal.orderNo]
          ? ""
          : (newobj[curVal.orderNo] = preVal.push(curVal));
        return preVal;
      }, []);
      console.log("礼品网接收订单完成", this.tbOrderList);
      this.orderDisabled = false;
      this.orderDisabledRest = false;
      this.orderTotal = data.order.total;
      this.orderFinish = true;
    },
    // 部分订单
    overOrderPart(data) {
      const arr = data.order.order_list;
      this.tbOrderList = [...this.tbOrderList, ...arr];
      // 订单去重
      const newobj = {};
      this.tbOrderList = this.tbOrderList.reduce((preVal, curVal) => {
        newobj[curVal.orderNo]
          ? ""
          : (newobj[curVal.orderNo] = preVal.push(curVal));
        return preVal;
      }, []);
      console.log("礼品网接收部分订单", this.tbOrderList);
      this.orderDisabled = false;
      this.needSlide = true;
      this.orderFinish = false;
      this.orderTotal = data.order.total;
    },
    // 完成滑块
    overSlider() {
      this.$confirm({
        title: "需要滑块验证，是否已完成",
        cancelText: "取消",
        okText: "确定",
        onOk: () => {
          console.log("滑块完成");
          // 重新查询订单loading
          this.orderDisabled = true;
          window.postMessage({ type: "get_order" }, "*");
        },
        onCancel() {}
      });
    },
    // 登录客户端的店铺
    async loginClient(type, login) {
      // 登录淘宝，打开客户端页面，获取cookie
      if (type === "TAOBAO") {
        const cookieInfo = await new Promise(resolve => {
          try {
            if (login) {
              const res = sendMessageSync(
                {
                  openUrl:
                    "https://trade.taobao.com/trade/itemlist/list_sold_items.htm",
                  closeReferrer: "itemlist/SoldQueryAction",
                  closeFilter: { urls: ["*://*.taobao.com/*"] },
                  closeUrl: "itemlist/asyncSold.htm",
                  executeJavaScript:
                    "try{document.getElementsByClassName('switch-btn')[0].click();} catch(e){} try{Array.from(document.getElementsByClassName('tabs-mod__tab___1e6Y8')).forEach(it => {if (it.getAttribute('data-reactid') && it.getAttribute('data-reactid').indexOf('waitSend') > -1  && it.className.indexOf('selected') < 0 ){it.click()}});} catch(e){if(location.href.indexOf('itemlist/list_sold_items.htm') > -1){document.getElementsByClassName('button-mod__primary___TqWy8')[0].click()}}try{}catch(e){} " // 异常处理，先不管这个
                },
                "cookieGet"
              );
              resolve(res.cookies);
            } else {
              resolve(this.clientLogin.taobao.cookie);
            }
          } catch (error) {
            console.info("登录失败", error);
          }
        });

        if (cookieInfo) {
          this.clientLogin.taobao.login = true;
          this.clientLogin.taobao.cookie = cookieInfo;
          try {
            const nicknameList = ["sn", "lid", "dnk", "tracknick", "_nk_"];
            const nameList = cookieInfo
              .split(";")
              .map(item => {
                const [key, value] = item.split("=");
                // eslint-disable-next-line
                if (nicknameList.includes(key))
                  return eval("'" + decodeURIComponent(decodeURI(value)) + "'");
              })
              .filter(item => item !== undefined);

            this.clientLogin.taobao.name = nameList[0] || "";
            this.clientLogin.taobao.id = cookieInfo
              .split("unb=")[1]
              .split(";")[0];
            // TODO 登录成功，持久化 clientLogin
            this.saveClient();
            let headers = {
              "Accept-Encoding": "deflate, gzip",
              "Accept-Language": "zh-CN,zh;q=0.9",
              accept: "application/json, text/javascript, */*; q=0.01",
              authority: "trade.taobao.com",
              "content-type":
                "application/x-www-form-urlencoded; charset=UTF-8",
              origin: "https://trade.taobao.com",
              referer:
                "https://trade.taobao.com/trade/itemlist/list_sold_items.htm?spm=a2oq0.12575281.favorite.d672.16c11debpOviq7&mytmenu=ymbb",
              "sec-ch-ua-mobile": "?0",
              "sec-fetch-dest": "empty",
              "sec-fetch-mode": "cors",
              "sec-fetch-site": "same-origin",
              "x-requested-with": "XMLHttpRequest",
              cookie: cookieInfo
            };
            try {
              const x5Headers = await x5CookieHeader(cookieInfo);
              if (x5Headers && x5Headers.cookie) {
                headers = x5Headers;
              }
            } catch (error) {
              console.log("[获取header失败,使用本地headers]", error);
            }
            // 2.爬取本地订单
            let pageNum = 1;
            const taobaoOrderData = {
              action: " itemlist/SoldQueryAction",
              buyerNick: " ",
              dateBegin: " ",
              dateEnd: " ",
              itemTitle: " ",
              orderStatus: " PAID",
              pageNum: " 1",
              pageSize: " 15",
              queryMore: " true"
            };
            this.clientOrderListDisabled = true;
            do {
              try {
                taobaoOrderData.pageNum = pageNum;
                const orderList = await requestClientBase({
                  method: "POST",
                  headers,
                  url:
                    "https://trade.taobao.com/trade/itemlist/asyncSold.htm?event_submit_do_query=1&_input_charset=utf8",
                  data: qs.stringify(taobaoOrderData)
                });
                console.log("orderList", orderList);
                // 订单总数
                this.clientLogin.taobao.orderTotal = orderList.page.totalNumber;
                // 放到list里
                this.clientOrderList = [];
                orderList.mainOrders.forEach(mainOrder => {
                  try {
                    // 获取地址和地址等信息
                    sendMessage(
                      { cookie: headers.cookie, orderId: mainOrder.id },
                      "get-mobile-address"
                    ).then(res => {
                      const { buyer, mobile, address } = res.result;
                      this.clientOrderList.push({
                        orderNo: mainOrder.id,
                        name: buyer,
                        mobile,
                        addressStr: address
                      });
                    });
                  } catch (e) {
                    console.info("e", e);
                  }
                });
                pageNum++;
                // 本次订单没有查到15个跳出
                if (orderList.mainOrders.length < 15) {
                  pageNum = 0;
                }
              } catch (error) {
                // 异常跳出
                pageNum = 0;
              }
            } while (pageNum > 0);
            this.clientOrderListDisabled = false;
            console.log("this.clientOrderList", this.clientOrderList);
          } catch (error) {
            console.log("登录失败", error);
            this.clientLogin.taobao.login = false;
            this.clientLogin.taobao.name = "登录失败";
            this.$message.error("店铺登录失败，请重试");
          }
        }
      }
    },
    // 持久化客户端
    saveClient() {
      // 如果存在就保持
      if (this.clientLogin.taobao.name && this.clientLogin.taobao.id) {
        Vue.ls.set(CLIENT_LOGIN, this.clientLogin, 7 * 24 * 60 * 60 * 1000);
        // 使用客户端持久化的信息，修改标题
        setDocumentTitle(
          `礼品代发-已登录淘宝店铺（${this.clientLogin.taobao.name}）`
        );
      }
    },
    // 客户端下单
    async showClientOrder() {
      // clientOrderList 没有数据时不显示表格
      if (this.clientOrderList.length === 0) {
        return this.$notification["error"]({
          message: "添加失败",
          description: "订单列表为空或者订单不可修改，请点击刷新或者重新登录",
          duration: 3
        });
      }

      for (const item of this.clientOrderList) {
        console.log("item", item);
        const addressAll = await this.setAddressRsult2(item.addressStr);
        item.province = addressAll.province;
        item.city = addressAll.city;
        item.county = addressAll.county;
        item.address = addressAll.address;
      }
      this.dataSource = JSON.parse(JSON.stringify(this.clientOrderList));
      this.outputs = JSON.parse(JSON.stringify(this.clientOrderList));
      this.showFilterOrderModal();
      this.showCheckGoodsInfo(this.dataSource);
      this.extShopId = this.clientLogin.taobao.id;
      this.shopName = this.clientLogin.taobao.name;
    },
    // 插件下单
    async showPluginOrder() {
      for (const item of this.tbOrderList) {
        const addressAll = await this.setAddressRsult2(item.addressStr);
        item.province = addressAll.province;
        item.city = addressAll.city;
        item.county = addressAll.county;
        item.address = addressAll.address;
      }
      this.dataSource = JSON.parse(JSON.stringify(this.tbOrderList));
      this.outputs = JSON.parse(JSON.stringify(this.tbOrderList));
      this.showFilterOrderModal();
      this.showCheckGoodsInfo(this.dataSource);
      this.extShopId = this.pluginUser.taobao.id;
      console.log(this.extShopId);
    },
    showPluginDoc() {
      window.open("https://docs.qq.com/doc/DY0t2UVZnSm9mc09T");
    },
    topLink() {
      if (this.topAds.link) {
        window.open(this.topAds.link);
      }
    },
    rightLink() {
      if (this.rightAds.link) {
        window.open(this.rightAds.link);
      }
    },
    hanleGetExtensionList() {
      const params = {
        pageSize: 100,
        pageNum: 1
      };
      extensionListGetUser(params).then(res => {
        if (res.success) {
          this.rightAds = res.data.filter(item => {
            return item.position === "RIGHT";
          })[0];
          this.topAds = res.data.filter(item => {
            return item.position === "TOP";
          })[0];
        }
      });
    },

    // 支付完成回来刷新页面
    gobackTopurchase() {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      this.firstTypeSelected = false;
      this.secondTypeSelected = false;
      this.firstTypeId = "";
      this.secondTypeId = "";
      this.fileList = [];
      this.dataSource = [];
      this.giftLists = [];
      this.giftInfo = [];
      this.storeOrderList = [];
      this.storeId = "";
      this.shopName = "";
      this.tragId = "";
      this.goodSelectedId = "";
      this.checkGoodsInfo = false;
      this.addressUid = "";
      this.handleGetGoodListFirst();
      this.getAddressListAll();
    },

    // 前往订单列表
    haspay() {
      this.$router.push("/order/normal");
    },

    // 店铺筛选
    showStoreOrder() {
      if (!this.storeId) {
        return this.$message.error("请先选择店铺");
      }
      // 判断是否授权过期
      let timeout = false;
      this.storeOrderList.forEach(item => {
        if (item.id === this.storeId) {
          if (item.refreshExpiresIn < new Date().getTime()) {
            timeout = true;
          }
        }
      });
      // 过期 不拉订单 弹框提示
      if (timeout) {
        this.$confirm({
          title: "店铺授权过期，是否重新绑定",
          cancelText: "取消",
          okText: "确定",
          onOk: () => {
            this.$router.push({ name: "storeBind" });
          },
          onCancel() {}
        });
      } else {
        // 不过期 拉订单
        // const timeStamp = new Date().getTime()
        // 没有选择时间则使用近 15 天
        let [timeStamp, lastTimeStamp] = [0, 0];
        if (this.importTimeList.length === 0) {
          timeStamp = moment()
            .add(-15, "d")
            .startOf("day")
            .valueOf();
          lastTimeStamp = moment()
            .endOf("day")
            .valueOf();
        } else {
          timeStamp = moment(this.importTimeList[0]).valueOf();
          lastTimeStamp = moment(this.importTimeList[1]).valueOf();
        }
        // TODO 如果是拼多多订单
        const pddPati = window.pddPati; // 拼多多客户端指纹
        // if (this.firstTypeId == 'PDD') {
        //   pddPati = window.pddPati
        //   // TODO 原来是7天，不知道是为啥
        //   // lasttimeStamp = timeStamp - 1000 * 3600 * 24 * 7;
        //   lasttimeStamp = timeStamp - 1000 * 3600 * 24 * 30
        // } else {
        //   lasttimeStamp = timeStamp - 1000 * 3600 * 24 * 30
        // }
        this.spinning = true;
        // this.currentFilterWay = 0 订单筛选  1 买家账号筛选
        const orderNoList = !this.currentFilterWay
          ? []
          : this.userInputContent
              .trim()
              .split("\n")
              .filter(address => address.trim() !== "");
        const params = {
          pddPati,
          createdStart: timeStamp,
          createdEnd: lastTimeStamp,
          noteColor:
            this.firstTypeId === "DOUYIN" ? dyFlag[this.tragId] : this.tragId,
          orderNoList: orderNoList.length > 100 ? [] : orderNoList
        };
        storeOrders(this.storeId, params)
          .then(res => {
            if (res.success) {
              const data = res.data.list.filter(val => val);
              data.forEach((item, index) => {
                item.id = index;
                item.key = index + 1;
                item.addressStr =
                  item.province + item.city + item.county + item.address;
              });
              this.outputs = data;
              this.spinning = false;
              this.showFilterOrderModal();
              this.showCheckGoodsInfo(this.dataSource);
            } else {
              this.spinning = false;
              this.$message.error(res.msg);
            }
          })
          .catch(e => {
            console.log("[e] ---> ", e);
            this.spinning = false;
            this.$message.error("出错啦～");
          });
      }
    },
    // 选择店铺
    handleStoreChange(value) {
      this.storeId = value;
      this.storeOrderList.forEach(item => {
        if (item.id === value) {
          this.shopName = item.shopName;
        }
      });
    },
    arrNewSet,
    // 获取店铺
    getStoreList() {
      const params = { type: this.firstTypeId };
      storeList(params).then(res => {
        if (res.success) {
          this.storeOrderList = this.arrNewSet(res.data.list);
          if (res.data.page.total > 0) {
            this.storeId = this.storeOrderList[0].id;
            this.shopName = this.storeOrderList[0].shopName;
          }
        } else {
          this.$message(res.msg);
        }
      });
    },

    // 下一步
    gotopHandle() {
      var step3_div = document.getElementById("step3_div");
      step3_div.scrollIntoView();
    },
    // 重新上传
    gobackHandle() {
      this.checkGoodsInfo = false;
      this.showUpload = true;
      this.fileList = [];
      this.dataSource = []; // 清空收货人
      this.showcheckInput = false;
      this.modalOneContent = "";
      this.modalTwoData = [];
    },
    // 选择仓库
    onAddressChange(id) {
      if (!this.firstTypeSelected) {
        this.$message.warning("请先选择平台");
        return;
      }

      // 清空费用和快递
      this.payParams.logisticsId = "";
      this.deliveryList = [];
      this.addressUid = id;
      this.giftLists = [];
      this.giftInfo = {};
      // this.goodsSumPrice = 0;
      // this.deliverySumPrice = 0;
      this.allPremium.count = 0;
      this.allPremium.price = 0;
      this.sortway = "no";
      this.getGoodsListAll();
    },
    // 首次获取全部礼品
    async handleGetGoodListFirst() {
      const res = await addressList();
      const addressArr = res.data;
      const arr = [];
      addressArr.forEach(item => {
        if (item.uid) {
          arr.push(item.uid);
        }
      });
      // 获取礼品
      const good = await goodsListAll({
        pageNum: this.page.pageNum,
        pageSize: 1000
      });
      const data = good.data;
      data.list = data.list.filter(item => {
        return arr.indexOf(item.uid) > -1;
      });

      data.list.forEach(item => {
        item.inWrap = false;
      });
      this.goodsList2 = data.list;
      // 本地分页
      this.goodsList = this.listPagination({
        current: this.page.pageNum,
        pageSize: this.page.pageSize,
        list: data.list
      });
      this.page = { total: data.list.length, pageSize: this.page.pageSize };
    },
    // 获取礼品
    async getGoodsListAll(type) {
      const params = {
        pageNum: this.page.pageNum,
        pageSize: 1000,
        uid: this.addressUid
      };
      const good = await goodsListAll(params);
      const data = good.data;
      data.list.forEach(item => {
        item.inWrap = false;
      });
      this.goodsList2 = data.list;
      this.goodsList = this.listPagination({
        current: 1,
        pageSize: this.page.pageSize,
        list: data.list
      });
      this.page = { total: data.list.length, pageSize: this.page.pageSize };
    },
    // 获取仓库
    getAddressListAll(type) {
      // 平台已选
      if (type) {
        addressList(type).then(res => {
          if (res.data[0]) {
            this.addressListData = res.data.sort((a, b) => {
              return a.sort - b.sort;
            });
            this.addressUid = this.addressListData[0].uid;
            // 有仓库才获取礼品
            this.getGoodsListAll();
          } else {
            // 没有仓库  仓库和礼品列表都清空
            this.addressUid = "";
            this.addressListData = [];
            this.goodsList = [];
          }
        });
      } else {
        // 平台未选  展示全部
        addressList().then(res => {
          // const all = {
          //   city: '',
          //   province: '全部',
          //   uid: '',
          //   detail: ''
          // }
          // res.data.unshift(all)
          this.addressListData = res.data.sort((a, b) => {
            return a.sort - b.sort;
          });
        });
      }
    },
    // 礼品排序
    changeSortWay(way) {
      this.sortway = way;
      const arr = JSON.parse(JSON.stringify(this.goodsList));
      const wayObj = {
        no: () => {
          this.goodsList = this.goodsList2;
        },
        price: () => {
          this.goodsList = arr.sort((a, b) => a.price - b.price);
        },
        sales: () => {
          this.goodsList = arr.sort((a, b) => a.sales - b.sales);
        },
        weight: () => {
          this.goodsList = arr.sort((a, b) => a.weight - b.weight);
        }
      };
      wayObj[way]();
    },
    // 鼠标移入礼品
    handleMouseover(index) {
      this.goodsList[index].inWrap = true;
    },
    // 鼠标移出礼品
    handleMouseout(index) {
      this.goodsList[index].inWrap = false;
    },
    // 选择礼品
    handleSelect(goods) {
      if (!this.firstTypeSelected) {
        this.$message.warning("请先选择平台");
        return;
      }

      const warehouseObj = this.addressListData.filter(
        item => item.uid === this.addressUid
      )[0];
      this.goodSelectedId = goods.id;
      const newGoods = {
        ...goods,
        sum: goods.price,
        warehouse: warehouseObj.province + warehouseObj.city
      };
      this.getGiftLists(newGoods);
    },
    // 选择礼品后
    getGiftLists(gift) {
      if (!gift) return;
      this.giftLists = [gift];
      this.giftInfo = {
        count: 1,
        weight: gift.weight,
        goodsPrice: gift.price,
        sumPrice: gift.price,
        deliveryPrice: null
      };

      this.getDelivery(gift.uid);
      this.getPremiumList(gift.uid);
      this.checkPremium();
    },
    // 页数更改
    onShowSizeChange(current, size) {
      this.page.pageSize = size;
      this.goodsList = this.listPagination({
        current,
        pageSize: size,
        list: this.goodsList2
      });
      // if (this.addressUid) {
      //   this.getGoodsListAll()
      // } else {
      //   this.handleGetGoodListFirst()
      // }
    },
    // 分页更改
    pageChange(page) {
      this.page.pageNum = page;
      // 使用本地分页
      this.goodsList = this.listPagination({
        current: page,
        pageSize: this.page.pageSize,
        list: this.goodsList2
      });
      // if (this.addressUid) {
      //   this.getGoodsListAll()
      // } else {
      //   this.handleGetGoodListFirst()
      // }
    },
    // 接收淘宝密码
    handleOk() {
      const formdata = new FormData();
      formdata.append("file", this.taobaoFile);
      formdata.append("password", this.taobaoPassword.trim());
      getExcelPassword(formdata).then(res => {
        if (res.success) {
          this.taobaoObj = res.data;
          this.uploadStatus = true;
          this.taobaoObj = res.data.map(item => {
            // 含隐私号
            if (this.tableFormat === "tablePrivacy") {
              const obj = {
                订单编号: item[0],
                收货人姓名: item[12],
                联系手机: item[16],
                收货地址: item[13],
                买家会员: item[12],
                商品标题: item[20]
              };
              return obj;
            }
            const obj = {
              订单编号: item[0],
              收货人姓名: item[14],
              联系手机: item[18],
              收货地址: item[15],
              买家会员: item[1],
              商品标题: item[21]
            };
            return obj;
          });
          console.log(this.taobaoObj);
          this.formatOrderInfo(
            this.taobaoObj,
            "订单编号",
            "收货人姓名",
            "联系手机",
            "收货地址",
            "买家会员",
            "商品标题"
          );
        } else if (!res.success) {
          this.$message.error(res.msg);
          this.uploadStatus = false;
        }
      });
      this.taobaoPassword = "";
      this.showPasswordInput = false;
    },

    // 上传官方模板
    async handleChangeUploadGuanFang(files) {
      const file = files.file;
      // 1.格式
      const typeSplit = file.name.toLowerCase().split(".");
      const fileType = typeSplit[typeSplit.length - 1];

      if (!file) {
        return false;
      } else if (
        ["csv", "xls", "xlsx", "xlsm", "xltx"].indexOf(fileType) === -1
      ) {
        this.$message.error(
          "上传格式不正确，请上传xls或者xlsx、csv、xlsm、xltx格式"
        );
        return false;
      }
      // 2.

      // 2.2 是否简易
      if (["xls", "xlsx", "xlsm", "xltx"].indexOf(fileType) === -1) {
        // 2.2.1csv后面判断
        this.readCsv(file);
      } else {
        // 2.2.2判断是不是简易模板 先读对象
        const fileReader = new FileReader();
        fileReader.onload = async ev => {
          try {
            const data = ev.target.result;
            const workbook = XLSX.read(data, {
              type: "binary"
            });
            const wsname = workbook.SheetNames[0];
            const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname]);

            // 第一条数据最后一项出现_Empty的问题
            const keyArr = Object.keys(ws[0]);
            if (
              keyArr[0] == "订单编号" &&
              keyArr[1] == "收货人姓名" &&
              keyArr[2] == "联系手机" &&
              keyArr[3] == "收货地址"
            ) {
              // 2.3简易
              this.readExcel(file);
            } else {
              // 2.4官方--类型
              if (this.firstTypeId == "JD") {
                await this.excelToJson(
                  file,
                  "订单号",
                  "客户姓名",
                  "联系电话",
                  "客户地址",
                  "下单帐号",
                  "商品名称"
                );
              }
              if (this.firstTypeId == "ALIBABA") {
                await this.excelToJson(
                  file,
                  "订单编号",
                  "收货人姓名",
                  "联系手机",
                  "收货地址",
                  "买家会员名",
                  "货品标题"
                );
              }
              if (this.firstTypeId == "PDD") {
                await this.excelToJson(
                  file,
                  "订单号",
                  "收货人",
                  "手机",
                  "收货地址",
                  "买家会员名",
                  "商品"
                );
              }
              if (this.firstTypeId == "TAOBAO") {
                await this.excelToJson(
                  file,
                  "订单编号",
                  "收货人姓名",
                  "联系手机",
                  "收货地址",
                  "买家会员名",
                  "宝贝标题"
                );
              }
            }
          } catch (e) {
            if (e) {
              this.showPasswordInput = true;
              this.taobaoFile = file;
            }
          }
        };
        fileReader.readAsBinaryString(file);
      }
      // }
    },

    // 简易表格导入
    async readExcel(file) {
      const that = this;
      const fileReader = new FileReader();
      fileReader.onload = async ev => {
        try {
          const data = ev.target.result;
          const workbook = XLSX.read(data, {
            type: "binary"
          });
          const wsname = workbook.SheetNames[0]; // 取第一张表
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname]); // 生成json表格内容
          console.log(ws);
          const newws = ws.map(item => {
            const temp = {};
            for (const key in item) {
              const k = key.replace(/\s+/g, "");
              temp[k] = item[key].toString().trim();
            }
            temp["订单编号"] = temp["订单编号"]
              ? temp["订单编号"].toString().replace(/[^0-9Aa-zZ-]/gi, "")
              : "";
            temp["联系手机"] = temp["联系手机"]
              ? temp["联系手机"].toString().replace(/[^0-9]/gi, "")
              : "";
            return temp;
          });
          that.outputs = []; // 清空接收数据
          const resultData = [];
          console.log(newws);
          for (let i = 0; i < newws.length; i++) {
            if (
              !newws[i]["订单编号"] ||
              !newws[i]["收货人姓名"] ||
              !newws[i]["联系手机"] ||
              !newws[i]["收货地址"]
            ) {
              console.log(newws[i]);
              that.$message.error(
                `请确保地址填写完整, 第${i + 1}条 ${newws[i]["订单编号"]} ${
                  newws[i]["收货人姓名"]
                } ${newws[i]["联系手机"]} ${newws[i]["收货地址"]} `
              );
              that.fileList = [];
              return;
            }
            if (newws[i]["订单编号"].length <= 4) {
              that.$message.error(
                `订单编号的长度不能小于4位数：第${i + 1}条 ${
                  newws[i]["订单编号"]
                } ${newws[i]["收货人姓名"]} ${newws[i]["联系手机"]} ${
                  newws[i]["收货地址"]
                } `
              );
              that.fileList = [];
              return;
            }
            if (newws[i]["收货人姓名"].length >= 10) {
              that.$message.error(
                `收货人姓名不能超过10个字符：第${i + 1}条 ${
                  newws[i]["订单编号"]
                } ${newws[i]["收货人姓名"]} ${newws[i]["联系手机"]} ${
                  newws[i]["收货地址"]
                } `
              );
              that.fileList = [];
              return;
            }
            const addressAll = await this.setAddressRsult2(
              newws[i]["收货地址"]
            );

            const sheetData = {
              id: i,
              key: i + 1,
              orderNo: newws[i]["订单编号"],
              name: newws[i]["收货人姓名"],
              buyer: newws[i]["买家会员名"],
              mobile: newws[i]["联系手机"].replace("86-", ""),
              province: addressAll.province,
              city: addressAll.city,
              county: addressAll.county,
              address: addressAll.address,
              addressStr: newws[i]["收货地址"],
              goodsName: newws[i]["宝贝标题"]
            };
            resultData.push(sheetData);
          }
          that.outputs = resultData;
          // 导入后直接显示
          this.showUpload = false;
          // this.filterWay("订单编号");
          this.showcheckInput = true;
          this.showCheckGoodsInfo(this.outputs);
        } catch (e) {
          if (e == "Error: File is password-protected") {
            that.$message.error("该文件需要密码");
          }
        }
      };
      fileReader.readAsBinaryString(file);
    },
    // 简易csv导入
    readCsv(file) {
      Papa.parse(file, {
        delimiter: "",
        newline: "",
        header: true,
        dynamicTyping: false,
        preview: 0,
        encoding: "GB2312",
        worker: false,
        comments: false,
        step: undefined,
        error: undefined,
        download: false,
        skipEmptyLines: true,
        chunk: undefined,
        fastMode: undefined,
        beforeFirstChunk: undefined,
        complete: res => {
          const keyArr = Object.keys(res.data[0]);
          // 1.是简易模板
          if (
            keyArr[0] == "订单编号" &&
            keyArr[1] == "收货人姓名" &&
            keyArr[2] == "联系手机" &&
            keyArr[3] == "收货地址"
          ) {
            this.formatOrderInfo(
              res.data,
              "订单编号",
              "收货人姓名",
              "联系手机",
              "收货地址",
              "买家会员名",
              "商品"
            );
          } else {
            // 2.官方模板
            if (this.firstTypeId == "JD") {
              this.csvToJson(
                file,
                "订单号",
                "客户姓名",
                "联系电话",
                "客户地址",
                "下单帐号",
                "商品名称"
              );
            }
            // ALIBABA
            if (this.firstTypeId == "ALIBABA") {
              this.csvToJson(
                file,
                "订单编号",
                "收货人姓名",
                "联系手机",
                "收货地址",
                "买家会员名",
                "货品标题"
              );
            }
            // PDD
            if (this.firstTypeId == "PDD") {
              this.csvToJson(
                file,
                "订单号",
                "收货人",
                "手机",
                "收货地址",
                "买家会员名",
                "商品"
              );
            }
            if (this.firstTypeId == "TAOBAO") {
              this.excelToJson(
                file,
                "订单编号",
                "收货人姓名",
                "联系手机",
                "收货地址",
                "买家会员名",
                "宝贝标题"
              );
            }
          }
        }
      });
    },
    // csv----json
    csvToJson(file, num, name, tel, address, buyer, goodsName) {
      let data;
      Papa.parse(file, {
        delimiter: "",
        newline: "",
        header: true,
        dynamicTyping: false,
        preview: 0,
        encoding: "GB2312",
        worker: false,
        comments: false,
        step: undefined,
        error: undefined,
        download: false,
        skipEmptyLines: true,
        chunk: undefined,
        fastMode: undefined,
        beforeFirstChunk: undefined,
        complete: async res => {
          data = res.data.filter(item => {
            return item[num] && item[name] && item[tel];
          });
          if (this.firstTypeId == "PDD") {
            const obj = data.map(item => {
              const item2 = {
                ...item,
                收货地址: item["省"]
                  .concat(item["市"])
                  .concat(item["区"])
                  .concat(item["街道"] ? item["街道"] : item["详细地址"])
              };
              return item2;
            });
            this.formatOrderInfo(
              obj,
              num,
              name,
              tel,
              address,
              buyer,
              goodsName
            );
          } else {
            this.formatOrderInfo(
              data,
              num,
              name,
              tel,
              address,
              buyer,
              goodsName
            );
          }
        }
      });
    },
    // excel----json
    async excelToJson(file, num, name, tel, address, buyer, goodsName) {
      const fileReader = new FileReader();
      fileReader.onload = async ev => {
        const data = ev.target.result;
        const workbook = XLSX.read(data, {
          type: "binary"
        });
        // 取第一张表
        const wsname = workbook.SheetNames[0];
        // 转换为json对象

        const ws = XLSX.utils
          .sheet_to_json(workbook.Sheets[wsname])
          .filter(item => {
            return item[num] && item[name] && item[tel];
          });

        // 拼多多的地址要处理
        if (this.firstTypeId == "PDD") {
          const obj = ws.map(item => {
            const item2 = {
              ...item,
              收货地址: item["省"]
                .concat(item["市"])
                .concat(item["区"])
                .concat(item["街道"] ? item["街道"] : item["详细地址"])
            };
            return item2;
          });
          await this.formatOrderInfo(
            obj,
            num,
            name,
            tel,
            address,
            buyer,
            goodsName
          );
        } else {
          await this.formatOrderInfo(
            ws,
            num,
            name,
            tel,
            address,
            buyer,
            goodsName
          );
        }
      };
      fileReader.readAsBinaryString(file);
    },
    // 生成订单数据
    // file文件data对象num订单编号name收货人姓名tel联系手机address收货地址buyer买家会员goodsName商品标题
    // 接收对象生成订单数据
    async formatOrderInfo(data, num, name, tel, address, buyer, goodsName) {
      const that = this;
      const newws = data.map(item => {
        const temp = {};

        for (const key in item) {
          const k = key.replace(/\s+/g, "");
          if (item[key]) {
            temp[k] = item[key].toString().trim();
          }
        }
        temp[num] = temp[num]
          ? temp[num].toString().replace(/[^0-9Aa-zZ-]/gi, "")
          : "";
        temp[tel] = temp[tel]
          ? temp[tel].toString().replace(/[^0-9]/gi, "")
          : "";
        return temp;
      });
      that.outputs = []; // 清空接收数据
      const resultData = [];
      for (let i = 0; i < newws.length; i++) {
        if (
          !newws[i][num] ||
          !newws[i][name] ||
          !newws[i][tel] ||
          !newws[i][address]
        ) {
          that.$message.error(
            `请确保地址填写完整, 第${i + 1}条 ${newws[i][num]} ${
              newws[i][name]
            } ${newws[i][tel]} ${newws[i][address]} `
          );
          that.fileList = [];
          return;
        }
        if (newws[i][num].length <= 4) {
          that.$message.error(
            `订单编号的长度不能小于4位数：第${i + 1}条 ${newws[i][num]} ${
              newws[i][name]
            } ${newws[i][tel]} ${newws[i][address]} `
          );
          that.fileList = [];
          return;
        }
        if (newws[i][name].length >= 16) {
          that.$message.error(
            `收货人姓名不能超过16个字符：第${i + 1}条 ${newws[i][num]} ${
              newws[i][name]
            } ${newws[i][tel]} ${newws[i][address]} `
          );
          that.fileList = [];
          return;
        }
        // 生成地址对象

        const addressAll = await this.setAddressRsult2(newws[i][address]);

        const sheetData = {
          id: i,
          key: i + 1,
          orderNo: newws[i][num],
          name: newws[i][name],
          buyer: newws[i][buyer],
          mobile: newws[i][tel].replace("86-", ""),
          province: addressAll.province || "11",
          city: addressAll.city || "12",
          county: addressAll.county || "22",
          address: addressAll.address || "33",
          addressStr: newws[i][address].trim(),
          goodsName: newws[i][goodsName]
        };

        resultData.push(sheetData);
      }
      that.outputs = resultData;
      // 直接显示在列表中
      this.showUpload = false;
      // this.filterWay("订单编号");
      this.showcheckInput = true;
      this.showCheckGoodsInfo(this.outputs);
    },
    // 筛选--删除
    deleteData(newData) {
      this.dataSource = newData;
      this.checkPremium();
    },
    changeMobile(newData) {
      this.dataSource = newData;
      this.checkPremium();
    },
    // 第一步选择
    selectFirstType(record) {
      // 清空
      this.secondTypeId = "";
      this.goodSelectedId = "";
      this.giftLists = [];
      this.goodsList = [];
      this.addressUid = "";
      this.giftInfo = [];
      this.firstTypeSelected = true;
      this.firstTypeId = record.id;
      this.storeId = "";
      this.shopName = "";
      this.showcheckInput = false;
      this.checkGoodsInfo = false;
      this.fileList = [];
      this.secondTypeSelected = true;
      this.dataSource = []; // 清空所有收件人
      this.page.pageNum = 1; // 礼品回到第一页
      this.extShopId = ""; // 插件店铺id
      // const { firstType } = this;
      // if (!this.cacheFirstType.length) {
      //   this.cacheFirstType = JSON.parse(JSON.stringify(firstType));
      // }
      // 第一步已选

      this.record.type = record.type;

      if (!this.cacheSecondType.length) {
        this.cacheSecondType = JSON.parse(JSON.stringify(this.secondType));
      }

      // 选择了第一步之后, 第二步会变化; 过一遍筛选
      if (this.firstTypeId === "TAOBAO" || this.firstTypeId === "ALIBABA") {
        this.secondType = JSON.parse(JSON.stringify(this.cacheSecondType));
      }
      if (
        this.firstTypeId === "JD" ||
        this.firstTypeId === "OTHER" ||
        this.firstTypeId === "ALIBABA"
      ) {
        this.secondType = JSON.parse(
          JSON.stringify(
            this.cacheSecondType.filter(
              item => item.id !== 1 && item.id !== 6 && item.id !== 7
            )
          )
        );
      }

      if (this.firstTypeId === "DOUYIN" || this.firstTypeId === "XHS") {
        this.secondType = JSON.parse(
          JSON.stringify(
            this.cacheSecondType.filter(
              item => item.id !== 5 && item.id !== 6 && item.id !== 7
            )
          )
        );
      }
      if (this.firstTypeId === "PDD") {
        // 1 店铺导入 item.id !== 1 &&
        this.secondType = JSON.parse(
          JSON.stringify(
            this.cacheSecondType.filter(item => item.id !== 6 && item.id !== 7)
          )
        );
      }
      // 获取仓库
      this.getAddressListAll(this.firstTypeId);
    },
    // 第二步选择
    selectSecondType(id) {
      if (!this.firstTypeSelected) {
        this.$message.warning("请先选择平台");
        return;
      }
      this.fileList = [];
      this.showcheckInput = false;
      this.checkGoodsInfo = false;
      this.modalOneContent = "";
      this.modalTwoParams = {};
      this.storeOrderList = [];
      this.storeId = "";
      this.shopName = "";
      this.dataSource = [];
      this.secondTypeSelected = true;
      this.secondTypeId = id;
      this.extShopId = ""; // 插件店铺id
      // 默认选择订单编号筛选
      this.filterWay("订单编号");
      if (this.secondTypeId == 1) {
        this.getStoreList();
      }
      this.showUpload = true;
      if (this.secondTypeId == 6) {
        console.log("插件安装状态", window.gift);
        if (window.gift == "0") {
          this.hasPlugin = true;
          this.orderDisabled = true;
          this.orderTotal = 0;
          this.orderFinish = false;
          this.tbOrderList = [];
          window.postMessage({ type: "get_user" }, "*");
        } else {
          this.hasPlugin = false;
        }
      }
      if (this.secondTypeId == 7) {
        console.log("客户端安装状态", window.client);
        if (window.client) {
          this.hasClient = true;
          this.orderDisabled = true;
          this.clientLogin.taobao.orderTotal = 0;
          this.orderFinish = false;
          this.clientOrderList = [];
          // window.postMessage({ type: 'get_user' }, '*');
          this.clientOrderListDisabled = true;
          this.loginClient("TAOBAO", this.clientLogin.taobao.cookie === "");
        } else {
          this.hasClient = false;
        }
      }
    },

    // 根据订单号或者买家名筛选
    showFilterOrderModal() {
      const { outputs, currentFilterWay } = this;
      this.errorOutputs = []; // 未筛选到的
      const arr = this.userInputContent
        .trim()
        .split("\n")
        .filter(address => address.trim() !== "");
      let data = [];
      const newOutputs = [];
      let newOutputsItemId = 0;
      let newOutputsItemKey = 1;
      outputs.forEach((item, index) => {
        item.id = newOutputsItemId;
        item.key = newOutputsItemKey;
        // 地址的逻辑问题
        newOutputs.push(item);
        newOutputsItemId++;
        newOutputsItemKey++;
      });
      if (arr.length) {
        arr.forEach((item, index) => {
          item = item.replace(/\s+/g, "");
          if (currentFilterWay) {
            // 按订单编号筛选
            const target = newOutputs.filter(ele => ele.orderNo === item);
            if (!target.length) {
              this.errorOutputs.push(item);
              return;
            }
            data.push(target[0]);
          } else {
            // 按买家账号筛选 一次返回多条
            const target = newOutputs.filter(ele => ele.buyer === item);
            if (!target.length) {
              this.errorOutputs.push(item);
              return;
            }
            data = [...data, ...target];
          }
        });
      } else {
        data = JSON.parse(JSON.stringify(newOutputs));
      }
      // 筛选后重新排序
      data.forEach((item, index) => {
        item.key = index + 1;
      });
      this.dataSource = data;
      // this.textareaPlaceholder = "";
      this.userInputContent = "";
      // this.$refs.filterOrder.showModal(data);
    },
    // 数据列表
    showCheckGoodsInfo(data) {
      const dataClone = JSON.parse(JSON.stringify(data));
      this.dataSource = dataClone;
      this.checkPremium();
      this.checkGoodsInfo = true;
    },
    // 检查溢价
    checkPremium() {
      const { dataSource, premiums, allPremium, notSend } = this;
      allPremium.count = 0;
      allPremium.price = 0;
      this.allNoSend = 0;
      // 判断溢价
      if (dataSource.length > 0) {
        dataSource.map(it => {
          let premiumPrice = 0;
          premiums.map(premium => {
            if (
              premium.province &&
              it.province &&
              it.province.indexOf(premium.province) > -1
            ) {
              if (premium.city) {
                if (it.city && it.city.indexOf(premium.city) > -1) {
                  if (premium.county) {
                    if (it.county && it.county.indexOf(premium.county) > -1) {
                      premiumPrice = premium.price;
                    }
                  } else {
                    premiumPrice = premium.price;
                  }
                }
              } else {
                premiumPrice = premium.price;
              }
            }
          });
          notSend.map(premium => {
            if (
              premium.province &&
              it.province &&
              it.province.indexOf(premium.province) > -1
            ) {
              if (premium.city) {
                if (it.city && it.city.indexOf(premium.city) > -1) {
                  if (premium.county) {
                    if (it.county && it.county.indexOf(premium.county) > -1) {
                      this.allNoSend++;
                    }
                  } else {
                    this.allNoSend++;
                  }
                }
              } else {
                this.allNoSend++;
              }
            }
          });
          if (premiumPrice > 0) {
            allPremium.count += 1;
            allPremium.price += premiumPrice;
          }
        });
        allPremium.price = parseFloat(allPremium.price).toFixed(2);
      }
    },
    // 移除文件图标
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
      this.showPasswordInput = false;
      this.outputs = [];
      this.showCheckGoodsInfo(this.outputs);
      this.uploadParams = {};
      this.checkGoodsInfo = false;
    },
    beforeUpload(file) {
      this.fileList = [file];
      return false;
    },
    // 手工填写模板切换
    selectModal(id) {
      this.checkGoodsInfo = false;
      this.secondType4Modal = id;
    },
    // 筛选方式
    filterWay(val) {
      if (val === "买家账号") {
        this.currentFilterWay = 0;
        this.textareaPlaceholder = "示例:\n买家账号1\n买家账号2\n买家账号3";
      } else {
        this.currentFilterWay = 1;
        this.textareaPlaceholder = "多个订单编号，回车键换行，不输入则筛选全部";
      }
    },
    // 快递停发  溢价
    getPremiumList(uid) {
      premiumList({ uid: uid }).then(res => {
        this.premiums = [];
        this.notSend = [];
        if (res.success) {
          res.data.map(data => {
            if (data.send) {
              this.notSend.push(data);
            } else {
              this.premiums.push(data);
            }
          });
        }
        // 检查溢价
        this.checkPremium();
      });
    },
    // 选择礼品查快递
    getDelivery(uid) {
      addressLogisticsListByUser(uid).then(res => {
        if (res.success) {
          this.deliveryList = res.data;
          this.payParams.logisticsId = res.data[0].id;
          this.giftInfo.deliveryPrice = res.data[0].price;
        } else {
          this.$message(res.msg);
        }
      });
    },
    // 选快递
    handleSelectDelivery(value) {
      const target = this.deliveryList.filter(item => item.id === value);
      this.giftInfo.deliveryPrice = target[0].price;
      this.giftInfo.sumPrice =
        Number(this.giftInfo.deliveryPrice) + Number(this.giftInfo.goodsPrice);
    },
    // 看停发地址
    showNoSendModal() {
      // 打开弹框
      this.$refs.notSendModal.showModal(this.addressListData);
    },
    // 看仓库禁发关键词
    // showNoSendKey () {
    //   // 打开弹框
    //   this.$refs.notSendKeyModal.showModal(this.addressListData)
    // },
    // 手动一
    async handleModalOneOK() {
      if (this.modalOneContent.trim() == "") {
        this.$message.warning("请输入地址");
        return;
      }
      // TODO 文本添加
      const { modalOneContent } = this;
      const result = [];
      const addressList = modalOneContent
        .replace(/%/g, " ")
        .split("\n")
        .filter(address => address.trim() !== "");
      let lengthNameOver = false;
      let lengthNameOverIndex = 0;
      let lengthOrderNoIndex = 0;
      let lengthOrderNo = false;
      let orderError = false;
      let orderErrorIndex = 0;
      const regMobile = /^(?:((\+?86)|(\(\+86\)))?-?1[3456789]\d{9}|1[3-9]\d{9}(-\d{4})?)$/;
      // let addressIntact = true
      for (const address of addressList) {
        const index = addressList.indexOf(address);
        const newAddress = address.replace(/，/g, ",").replace(/\s+/g, "");
        const arr = newAddress.split(",");
        // 没有输入订单编号
        if (arr.length == 3) {
          // 自动用时间戳填充订单号
          arr.unshift(Date.parse(new Date()));
        }
        // 第三个必须为大手机号
        if (!regMobile.test(arr[2])) {
          this.$message.error(`手机号格式填写，请参考右侧示例！`);
          continue;
        }
        if (/[\u4e00-\u9fa5]/.test(arr[0])) {
          orderError = true;
          orderErrorIndex = index + 1;
        }
        if (arr[0].length <= 4) {
          lengthOrderNo = true;
          lengthOrderNoIndex = index + 1;
        }
        if (arr[1].length >= 10) {
          lengthNameOver = true;
          lengthNameOverIndex = index + 1;
        }
        arr[3] = newAddress
          .replace(arr[0], "")
          .replace(arr[1], "")
          .replace(arr[2], "")
          .replace(/,/g, "");
        const addressAll = await this.setAddressRsult2(arr[3]);
        console.info("addressAll20230526", JSON.stringify(addressAll));
        if (addressAll.address.length < 1) {
          const { city } = addressAll;
          let county = addressAll.county;
          let addressStr = "";
          if (county === "" || county === null) {
            const idx = arr[3].indexOf(city);
            addressStr = arr[3].substring(idx + city.length, arr[3].length);
          } else {
            const idx = arr[3].indexOf(county);
            const addr = arr[3].substring(idx + county.length, arr[3].length);
            if (addr[0] === "区") {
              addressStr = addr.substring(1);
              county = county + addr[0];
            } else if (addr[0] === "县") {
              addressStr = addr.substring(1);
              const lastCountyStr = county[county.length - 1];
              if (lastCountyStr !== "县") county = county + addr[0];
            } else {
              addressStr = addr;
            }
          }
          console.info("addressStr20230621", addressStr);
          addressAll.address = addressStr;
          addressAll.county = county;
        }
        console.log("[addressAll] ------> ", addressAll);
        // 包含北京市就停止
        if (!arr[1] || !arr[1].trim())
          return this.$message.error(
            `姓名不能为空，请按格式填写：第${index + 1}行`
          );
        const temp = {
          id: index,
          key: index + 1,
          orderNo: arr[0],
          name: arr[1],
          mobile: arr[2].replace("86-", "").replace("+", ""),
          province: addressAll.province,
          city: addressAll.city,
          county: addressAll.county,
          address: addressAll.address,
          addressStr: arr[3]
        };
        result.push(temp);
      }

      if (lengthNameOver) {
        return this.$message.error(
          `姓名长度不能超过10：第${lengthNameOverIndex}行`
        );
      }
      if (lengthOrderNo) {
        return this.$message.error(
          `订单号长度不能小于4位：第${lengthOrderNoIndex}行`
        );
      }
      if (orderError) {
        return this.$message.error(`订单号格式不正确：第${orderErrorIndex}行`);
      }
      this.showCheckGoodsInfo(result);
    },
    // 手动二
    handleModalTwoOk() {
      const { modalTwoParams } = this;
      if (!modalTwoParams.orderNo) {
        modalTwoParams.orderNo = Date.parse(new Date());
      } else if (!modalTwoParams.name) {
        this.$message.warning("请填写姓名");
        return;
      } else if (!modalTwoParams.mobile) {
        this.$message.warning("请填写手机号");
        return;
      } else if (
        !modalTwoParams.province ||
        !modalTwoParams.city ||
        !modalTwoParams.county ||
        !modalTwoParams.address
      ) {
        this.$message.warning("请填写完整收货地址");
        return;
      }
      if (/[\u4e00-\u9fa5]/.test(modalTwoParams.orderNo)) {
        this.$message.error("请输入正确的订单号");
        return;
      }
      if (modalTwoParams.name.trim().length >= 10) {
        this.$message.error("姓名长度不能超过10");
        return;
      }
      // 手机
      if (
        !/^((\+?86)|(\(\+86\)))?-?1[3456789]\d{9}$/.test(modalTwoParams.mobile)
      ) {
        this.$message.error("请输入正确的手机号码");
        return;
      }
      const orderInfo = {
        orderNo: modalTwoParams.orderNo,
        name: modalTwoParams.name,
        mobile: modalTwoParams.mobile.replace("86-", ""),
        province: modalTwoParams.province,
        city: modalTwoParams.city,
        county: modalTwoParams.county,
        address: modalTwoParams.address.replace(/%/g, " "),
        addressStr:
          modalTwoParams.province.replace(/%/g, " ") +
          modalTwoParams.city.replace(/%/g, " ") +
          modalTwoParams.county.replace(/%/g, " ") +
          modalTwoParams.address.replace(/%/g, " ")
      };
      this.modalTwoData.push(orderInfo);
      this.modalTwoData.forEach((item, index) => {
        item.id = index;
        item.key = index + 1;
      });
      this.showCheckGoodsInfo(this.modalTwoData);
      this.modalTwoParams = {};
    },
    async setAddressRsult2(addr) {
      // 手动导入的，才需要走高德
      const resultData = setAddressRsultJs(addr);
      let analysisAddr = await addressAnalysis(addr);
      try {
        // 从高德拿来的，也处理一下
        analysisAddr = analysisAddr.data.geocodes[0];
        if (analysisAddr) {
          if (
            analysisAddr.province &&
            analysisAddr.province.length > 1 &&
            resultData.province.length < 2
          ) {
            resultData.province = analysisAddr.province;
          }
          if (
            analysisAddr.city &&
            analysisAddr.city.length > 1 &&
            resultData.city.length < 2
          ) {
            resultData.city = analysisAddr.city;
          }
          if (
            analysisAddr.district &&
            analysisAddr.district.length > 1 &&
            resultData.district.length < 2
          ) {
            resultData.county = analysisAddr.district;
          }
          // 详细地址不处理
          // if (analysisAddr.formatted_address && analysisAddr.formatted_address.length > 2) { resultData['address'] = analysisAddr.formatted_address.split(analysisAddr.district)[1]; }
        }
      } catch (e) {}
      return resultData;
    },
    // 监听到筛选订单的有没有值
    listenerSelectedOrders(orders) {
      this.selectedOrders = [];
      this.selectedOrders.push(...orders);
      console.log("[this.selectedOrders] ---> ", this.selectedOrders);
    },
    // 批量删除
    deleteRows(deleteOrders) {
      console.log("[deleteOrders] ---> ", deleteOrders);
      if (!deleteOrders.length) return;
      const { dataSource } = this;
      const temp = dataSource.filter(
        val => !deleteOrders.includes(val.orderNo)
      );
      this.dataSource = temp;
    },
    // 提交订单
    handleOrderInsert() {
      if (this.giftLists.length === 0) {
        this.$message.error("请选择礼品");
        return;
      }
      this.errorOutputs = [];
      this.noSendAddress = [];
      let tempDataSource = null;
      if (this.selectedOrders.length) {
        tempDataSource = this.selectedOrders;
      } else {
        tempDataSource = this.dataSource;
      }
      console.log("[dataSource] ------> ", this.dataSource);
      if (!tempDataSource.length) {
        return this.$message.error("请至少填写一个收货地址");
      }
      const { comment, notSend } = this;
      const checkMobile = {}; // 手机号效验
      const pddCheckMobile = {}; // pdd虚拟手机号效验
      const checkName = {}; // 姓名效验
      const checkNoSend = {}; // 校验是否有拒绝发货的地址
      const checkAddress = {}; // 校验收货信息是否完整
      console.log("[tempDataSource] ---> ", tempDataSource);
      tempDataSource.forEach((item, index) => {
        if (this.firstTypeId === "DOUYIN" && this.secondTypeId === 1) {
          const hasDf =
            item.child?.filter(val => val.orderStatusDesc === "待发货") || [];
          const len = hasDf.length;
          if (len && len < item.child?.length) {
            item.subOrderId = hasDf.reduce(
              (sum, cur, index) =>
                (sum +=
                  index + 1 === len ? cur.subOrderId : `${cur.subOrderId},`),
              ""
            );
          }
        }
        if (!item.county) {
          item.county = "其他区";
        }
        if (
          !item.orderNo ||
          !item.city ||
          !item.name ||
          !item.province ||
          !item.mobile ||
          !item.county ||
          !item.address
        ) {
          checkAddress[index] = item;
        } else {
          if (
            item.mobile.length < 20 &&
            (!item.mobile || !/^1[3456789]\d{9}$/.test(item.mobile))
          ) {
            checkMobile[index] = item;
          } else {
            item.mobile = item.mobile.replace("86-", "");
          }
          if (
            item.mobile.length < 20 &&
            /\[\d{4}\]/.test(item.name) &&
            /^178|^184/.test(item.mobile)
          ) {
            pddCheckMobile[index] = item;
          }
          if (
            item.name.length < 20 &&
            /[^\a-\z\A-\Z0-9\u4E00-\u9FA5\$\.\【\】\(\)\（\）\[\]\?\_\<\>\。\-\——\=\;\@\！\!\+]/g.test(
              item.name
            )
          ) {
            checkName[index] = item;
          }
        }
        notSend.map(premium => {
          if (
            premium.province &&
            item.province &&
            item.province.indexOf(premium.province) > -1
          ) {
            if (premium.city) {
              if (item.city && item.city.indexOf(premium.city) > -1) {
                if (premium.county) {
                  if (item.county && item.county.indexOf(premium.county) > -1) {
                    this.errorOutputs.push(item.orderNo);
                    // checkNoSend[index] = item;
                    this.noSendAddress.push(item);
                  }
                } else {
                  this.errorOutputs.push(item.orderNo);
                  // checkNoSend[index] = item;
                  this.noSendAddress.push(item);
                }
              }
            } else {
              this.errorOutputs.push(item.orderNo);
              // checkNoSend[index] = item;
              this.noSendAddress.push(item);
            }
          }
        });
      });
      console.log("[tempDataSource] ---> ", tempDataSource);
      // const checkNoSendKey = [];
      // for (const key in checkNoSend) {
      //   checkNoSendKey.push(parseInt(key) + 1);
      // }
      // if (checkNoSendKey.length > 0) {
      //   //提示
      //   const send = checkNoSend[checkNoSendKey[0] - 1];
      //   return this.$message.error(
      //     '第' +
      //       checkNoSendKey[0] +
      //       '条数据是 ' +
      //       send.province +
      //       send.city +
      //       send.county +
      //       '，该地区已停发，请删除后再提交'
      //   );
      // }

      // TODO 拼多多的不验证了
      if (!["PDD", "TAOBAO"].includes(this.firstTypeId)) {
        const checkAddressKey = [];
        for (const key in checkAddress) {
          checkAddressKey.push(parseInt(key) + 1);
        }
        if (checkAddressKey.length > 0) {
          return this.$message.error(
            "第" + checkAddressKey[0] + "条数据收货信息不全；"
          );
        }
        const checkMobileKey = [];
        for (const key in checkMobile) {
          checkMobileKey.push(parseInt(key) + 1);
        }
        if (checkMobileKey.length > 0) {
          return this.$message.error(
            "第" + checkMobileKey[0] + "条数据手机号不正确；"
          );
        }
        const checkNameKey = [];
        for (const key in checkName) {
          checkNameKey.push(parseInt(key) + 1);
        }
        if (checkNameKey.length > 0) {
          return this.$message.error(
            "第" + checkNameKey[0] + "条数据收货人姓名不正确；"
          );
        }
      }
      if (this.firstTypeId === "PDD") {
        if (Object.keys(pddCheckMobile).length) {
          return this.$message.info(
            "开头为178/184的虚拟号不能下单！！！ 请修改虚拟号后重新下单"
          );
        }
      }
      if (this.noSendAddress.length > 0) {
        this.deleteNoSendModal = true;
      } else {
        const address = JSON.parse(JSON.stringify(tempDataSource));
        // 拼多多修改虚拟单号
        // if (this.firstTypeId == 'PDD') {
        //   address.forEach((item) => {
        //     if (item.virtual && /^1846694\d{4}$/.test(item.mobile)) {
        //       item.mobile = '13093856040'
        //     }
        //   })
        // }
        const store = this.storeId ? this.storeId : null;
        const filterRes = this.handleFilterKey(this.giftLists[0].uid, address);
        if (filterRes.code !== 0) return this.$message.error(filterRes.msg);
        const params = {
          id: this.giftLists[0].id,
          uid: this.giftLists[0].uid,
          logisticsId: this.payParams.logisticsId,
          detailArray: filterRes.detailArray,
          comment: comment
        };
        params.platformType = this.record.type;
        if (store) params.shopId = store;
        if (this.shopName) params.shopName = this.shopName;
        // 下单带上插件店铺id
        if (this.extShopId) params.extShopId = this.extShopId;
        // 读取本地订单
        const arr = JSON.parse(localStorage.getItem("detailArray"));
        // 1.有数据并且长度一样
        if (arr && arr.length == params.detailArray.length) {
          let same = 0;
          arr.forEach((item, index) => {
            // 每次一样  计数+1
            if (arr[index].orderNo == params.detailArray[index].orderNo) {
              same++;
            }
          });
          // 1.1遍历之后  计数==长度 全部一样
          if (same == arr.length && same == params.detailArray.length) {
            this.$confirm({
              title: "和上次提交内容一致，是否继续提交",
              cancelText: "取消",
              okText: "确定",
              onOk: () => {
                this.handleInsertRequest(params);
              },
              onCancel() {}
            });
          } else {
            // 1.2长度一样订单号不一样
            this.handleInsertRequest(params);
          }
        } else {
          // 2.没有数据或者长度不一样  直接提交
          this.handleInsertRequest(params);
        }
      }
    },
    /** 过滤禁发关键词 */
    handleFilterKey(selectUid, orderList) {
      // 找出仓库
      const addressFind = this.addressListData.find(
        val => val.uid === selectUid
      );
      if (!addressFind) return { code: -1, msg: "未找到对应的仓库" };
      const { noSendKey } = addressFind;
      const noSendKeyArr = noSendKey?.split(",").filter(val => val) || [];
      if (!noSendKeyArr.length) {
        return {
          code: 0,
          detailArray: orderList
        };
      }
      const regObj = new RegExp(noSendKeyArr.join("|"), "g");
      const errArr = [];
      orderList.forEach(item => {
        if (
          regObj.test(item.name) ||
          regObj.test(item.mobile) ||
          regObj.test(item.addressStr)
        ) {
          errArr.push(item.orderNo);
        }
      });
      if (errArr.length) {
        return {
          code: -1,
          msg: `订单${errArr.join(
            ","
          )}，通过快递不发货地点过滤后，没有可发货的地址信息，生成订单失败`
        };
      }
      return {
        code: 0,
        detailArray: orderList
      };
    },
    handleInsertRequest(params) {
      orderInsert(params).then(res => {
        if (res.success) {
          // 把数组缓存到本地
          localStorage.setItem(
            "detailArray",
            JSON.stringify(params.detailArray)
          );
          this.$refs.payWay.showModal(res.data, this.giftLists[0].name);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 用户信息
    getUserInfo() {
      sipUser().then(res => {
        this.user = res.data;
      });
    },

    // 一键清除停发
    handleDeleteAllNotSend() {
      const idArr = [];
      this.noSendAddress.forEach(item => {
        idArr.push(item.id);
      });
      const newData = this.dataSource.filter(i => {
        return idArr.indexOf(i.id) == -1;
      });
      newData.forEach((item, index) => {
        item.key = index + 1;
      });
      this.showCheckGoodsInfo(newData);
      this.errorOutputs = [];
      this.$message.success("已清除停发地址");
      this.deleteNoSendModal = false;
    },
    // 下载停发地址
    downLoadNoSend() {
      const tHeader = [
        "订单号",
        "姓名",
        "手机号",
        "省",
        "市",
        "县",
        "地址详情"
      ];
      const filterVal = [
        "orderNo",
        "name",
        "mobile",
        "province",
        "city",
        "county",
        "address"
      ];
      const data = this.noSendAddress.map(v => filterVal.map(j => v[j]));
      export_json_to_excel(tHeader, data, "停发地址列表");
    },
    // 搜索礼品
    searchGift() {
      console.log("[this.goodsList2] ---> ", this.goodsList2);
      this.goodsList = this.goodsList2.filter(item =>
        item.name.includes(this.searchGiftValue)
      );
      this.page = {
        pageNum: 1,
        pageSize: this.page.pageSize,
        total: this.goodsList.length
      };
    },
    // 重置搜索
    resetSearch() {
      this.searchGiftValue = "";
      this.goodsList = this.listPagination({
        current: 1,
        pageSize: 18,
        list: this.goodsList2
      });
      this.page = { pageNum: 1, pageSize: 18, total: this.goodsList2.length };
    },
    // 数组分页
    listPagination({ current, pageSize, list }) {
      const skipNum = (current - 1) * pageSize;
      return skipNum + pageSize >= list.length
        ? list.slice(skipNum, list.length)
        : list.slice(skipNum, skipNum + pageSize);
    }
  }
};
</script>

<style lang="less" scoped>
.purchase_banner {
  position: fixed;
  height: calc(100vh - 64px);
  display: flex;
  align-items: center;
  top: 64px;
  right: 32px;
  z-index: 1000;
  cursor: pointer;
  .close_father {
    position: relative;
  }
  .close_btn {
    width: 80px;
    height: 24px;
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
  }
}
// 头部
.purchase_header {
  margin: -24px -24px 24px;
  cursor: pointer;
  img {
    width: 100%;
    object-fit: cover;
  }
}
.nosend_btn {
  color: #f93244;
  cursor: pointer;
}
.step {
  background-color: #fff;
  padding: 16px 24px 24px;
  font-family: PingFang SC;
  margin-bottom: 24px;
  .stepTitle {
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 24px;
  }
  .step_body {
    display: flex;

    .step_body_item {
      cursor: pointer;
      width: 120px;
      height: 48px;
      border: 1px solid rgba(217, 217, 217, 1);
      border-radius: 4px;
      color: rgba(0, 0, 0, 0.65);
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 24px;

      &:hover {
        border: 1px solid #1890ff;
        box-shadow: 0 0 1px #1890ff;
      }
      &.active {
        border: 1px solid #1890ff;
        box-shadow: 0 0 1px #1890ff;
      }
    }
  }
  .step_body3 {
    display: flex;
    box-sizing: border-box;
    // 左边
    .gift_container {
      width: 848px;
      .address_list,
      .sort_list {
        display: flex;
        margin-bottom: 16px;
        ul {
          flex: 1;
          padding: 0;
          margin: 0;
          padding-left: 16px;
          list-style: none;
          li {
            cursor: pointer;
            float: left;
            margin-right: 24px;
            &.active {
              color: #1890ff;
            }
          }
        }
      }

      .goodsList {
        padding: 0;
        box-sizing: border-box;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        .btn {
          width: 100%;
          height: 40px;
          background: rgba(0, 0, 0, 0.65);
          font-size: 14px;
          text-align: center;
          line-height: 40px;
          position: absolute;
          top: 60%;
          z-index: 999;
          :hover {
            cursor: pointer;
          }
          a {
            display: block;
            width: 100%;
            height: 100%;
            color: #fff;
          }
        }
        li {
          position: relative;
          width: 182px;
          height: 212px;
          border-radius: 4px;
          margin-bottom: 24px;
          margin-right: 16px;
          border: 1px solid #f0f0f0;
        }
        .active {
          border: 1px solid #1890ff;
        }
        .poster {
          width: 180px;
          height: 160px;
          margin-bottom: 8px;
          img {
            display: inline-block;
            width: 180px;
            height: 160px;
            border-radius: 4px 4px 0 0;
          }
        }
        .price {
          color: #f93244;
          margin-left: 8px;
          margin-bottom: 4px;
          font-size: 12px;
        }
        .text {
          margin: 0 8px;

          color: rgba(0, 0, 0, 0.45);
          display: flex;
          justify-content: space-between;
          font-size: 12px;
          height: 14px;
          line-height: 14px;
          .title {
            // width: 93px;
            // overflow: hidden;
          }
        }
        .ant-pagination {
          text-align: right;
          margin-top: 10px;
        }
      }
      .ant-table-placeholder {
        border: 0;
      }
    }
    // 右边
    .gift_list_output {
      margin: 0 24px;
      width: 776px;
      .order_info {
        margin-bottom: 14px;
      }
      .order_btn {
        cursor: pointer;
        margin-top: 40px;
        width: 167px;
        height: 32px;
        opacity: 1;
        color: #fff;
        background-color: #f93244;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
.check_wrapper {
  background-color: #fff;
  padding: 24px;
  .error_info {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 2px;
  }
}
.selectWay {
  padding: 16px 24px 24px;
  background: #ffffff;
  vertical-align: middle;
  margin-bottom: 24px;
  display: flex;
  .middleLeft {
    margin-right: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .middleRight {
    background: #ffffff;
  }
  .modal2 {
    width: 520px;
  }

  .trag_list {
    margin: 24px 0 12px;
    display: flex;
    .trag_group {
      display: flex;
    }
    .radioStyle {
      display: flex;
      align-items: center;
    }
    .tragicon1 {
      width: 17px;
      height: 16px;
    }
    .tragicon2 {
      width: 12px;
      height: 13px;
    }
    .tragtb1 {
      background: url("~@/assets/css_sprites.png") -354px -570px;
    }
    .tragtb2 {
      background: url("~@/assets/css_sprites.png") -391px -570px;
    }
    .tragtb3 {
      background: url("~@/assets/css_sprites.png") -465px -570px;
    }
    .tragtb4 {
      background: url("~@/assets/css_sprites.png") -428px -570px;
    }
    .tragtb5 {
      background: url("~@/assets/css_sprites.png") -502px -570px;
    }
    .tragal1 {
      background: url("~@/assets/css_sprites.png") -558px -303px;
    }
    .tragal2 {
      background: url("~@/assets/css_sprites.png") -558px -336px;
    }
    .tragal3 {
      background: url("~@/assets/css_sprites.png") -642px -10px;
    }
    .tragal4 {
      background: url("~@/assets/css_sprites.png") -642px -43px;
    }
  }
  .store_bind_sure {
    width: 720px;
    display: flex;
    justify-content: space-between;
    .plugin_doc {
      margin-right: 16px;
      color: #1890ff;
      cursor: pointer;
    }
  }
  .plugin_pro {
    width: 720px;
    text-align: right;
    color: red;
  }
}
.filter {
  background-color: #fff;
  margin: 20px 0;
  padding: 16px 24px 24px;
  .tab {
    margin-bottom: 14px;
  }
}
/deep/ .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: #1890ff;
}
.page-transition-enter {
  opacity: 0;
}

.page-transition-leave-active {
  opacity: 0;
}

.page-transition-enter .page-transition-container,
.page-transition-leave-active .page-transition-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.resize_none {
  resize: none;
}
.nosend_warning {
  color: #f93244;
  height: 60px;
}
.plugin_wrapper {
  display: flex;
  align-items: center;
  .plugin_info {
    display: flex;
    align-items: center;
  }
  .pro_wrapper {
    margin: 0 16px 0 8px;
    display: flex;
    align-items: center;
    width: 200px;
    height: 21px;
  }
}
</style>
