import dayjs from "dayjs";
import { sendMessage } from "./message";


export const requestClientBase = async requestObj => {
  const headers = {
    "Accept-Encoding": "deflate, gzip",
    "Accept-Language": "zh-CN,zh;q=0.9",
    "user-agent": `Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/90.0.4430.212 Safari/537.36${dayjs().valueOf()}`,
    ...requestObj.headers,
    Cookie: requestObj.cookie ? requestObj.headers.cookie : requestObj.headers.cookies,
  };

  const submitParams = {
    ...requestObj,
    headers,
    responseEncoding: requestObj.responseEncoding || "utf8",
    responseType: requestObj.responseType || "json",
  };

  return await new Promise((resolve, reject) => {
    sendMessage(submitParams, "requestClient")
      .then(res => {
        if (res.response.err) {
          const errorObj = { errorTime: dayjs().valueOf(), url: submitParams.url, msg: res.response.msg };
          console.info('[errorObj] ------> ', errorObj)
          if (res.response.msg === "淘宝登录过期") {
            resolve({ err: true, msg: errorObj })
          }
          reject({ err: true, msg: errorObj });
        }
        resolve(res.response.data);
      })
      .catch(error => {
        console.log("requestClient.js-e", error);
        reject({ err: true, msg: `[requestClient.js-e]: ${error}` });
      });
  });
};

// /**
//  * TODO obj需要携带cookieId，从而指定cookie
//  * @param obj
//  * @param proxy
//  * size 重试次数
//  * @returns {Promise<unknown>}
//  */
// const requestClientBase1 = (obj /*, proxy = null */) => {
//   return new Promise((resolve, reject) => {
//     // console.log('proxy', proxy);
//     // 不存在cookieI
//     if (!obj.headers) obj.headers = {};

//     if (obj.cookie) {
//       obj.headers["Cookie"] = obj.cookie;
//     }
//     if (obj.cookies) {
//       obj.headers["Cookie"] = obj.cookies;
//     }
//     obj = {
//       headers: {
//         "Accept-Encoding": "deflate, gzip",
//         "Accept-Language": "zh-CN,zh;q=0.9",
//         "user-agent": `Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/90.0.4430.212 Safari/537.36${new Date().getTime()}`,
//         ...obj.headers,
//       },
//       data: obj.data,
//       url: obj.url,
//       method: obj.method,
//       responseEncoding: obj.responseEncoding || "utf8",
//       responseType: obj.responseType || "json",
//     };
//     sendMessage(obj, "requestClient")
//       .then(res => {
//         if (res.response.err) {
//           console.error("请求失败，node-1 Axios：" + obj.url + ":", res);
//         }
//         try {
//           resolve(res.response.data);
//         } catch (e) {
//           console.error("请求失败，node-2 Axios：" + obj.url + ":", res);
//           reject({
//             err: true,
//             msg: "请求成功，解析失败" + e,
//           });
//         }
//       })
//       .catch(e => {
//         console.log("requestClient.js-e", e);
//         reject({ err: true });
//       });
//   });
// };

// export { requestClientBase };
