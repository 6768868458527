<template>
  <a-modal
    v-model="visible"
    title="筛选订单"
    ok-text="确认收货信息"
    cancel-text="返回重新上传"
    @ok="handleOk"
    width="1000px">
    <p>根据收入内容，核对收件人信息</p>
    <!-- <div class="tips">验证失败0条，成功1条，总计1条</div> -->
    <a-table
      :columns="columns"
      :dataSource="dataSource"
      :pagination="true"
    >
      <template slot="operation" slot-scope="text, record">
        <a @click="handelDeleted(record)">删除</a>
      </template>
    </a-table>
  </a-modal>
</template>

<script>
const columns = [
  {
    title: '#',
    dataIndex: 'key'
  },
  {
    title: '第三方订单编号',
    dataIndex: 'orderNo'
  },
  {
    title: '姓名',
    dataIndex: 'name'
  },
  {
    title: '手机号',
    dataIndex: 'mobile'
  },
  {
    title: '省',
    dataIndex: 'province'
  },
  {
    title: '市',
    dataIndex: 'city'
  },
  {
    title: '县',
    dataIndex: 'county'
  },
  {
    title: '地址',
    dataIndex: 'address'
  },
  {
    title: '校检结果',
    dataIndex: 'status'
  },
  {
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' }
  }
]

export default {
  data () {
    return {
      columns,
      visible: false,
      dataSource: [],
      loading: false
    }
  },
  methods: {
    showModal (data) {
      this.dataSource = data
      this.visible = true
    },
    handleOk () {
      this.$emit('showCheckGoodsInfo', this.dataSource)
      this.visible = false
    },
    handelDeleted (record) {
      const { dataSource } = this
      const newData = dataSource.filter(item => item.id !== record.id)
      newData.forEach((item, index) => {
        item.id = index
        item.key = index + 1
      })
      this.dataSource = newData
    },
    onShowSizeChange(current, size) {
      this.page.pageSize = size;
    },
    pageChange(page) {
      this.page.pageNum = page;
    }
  }
}
</script>

<style lang="less" scoped>
.tips {
  padding: 20px;
  background: #f2f2f2;
  border-radius: 8px;
  margin-bottom: 14px;
}
</style>
