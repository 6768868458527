export const sendMessageSync = (message, type = "requestClient") => {
  const id = Math.floor(Math.random() * 10e10);
  return window.electron.ipcRenderer.sendSync(type, { id, message });
};

export const sendMessage = (message, type = "requestClient") => {
  return new Promise((resolve, reject) => {
    const id = Math.floor(Math.random() * 10e10);
    setTimeout(reject, 20e3);
    window.electron.ipcRenderer.send(type, { id, message });
    window.electron.ipcRenderer.on(type, (_, arg) => {
      if (arg.id === id) resolve(arg);
    });
  });
};

// // 刷新
// const refreshMessage = () => {
//   ipcRenderer.on("refresh-item", () => {
//     console.log('[321] ------> ', 321)
//     location.reload()
//   });
// };

// // 前进
// const forwardMessage = () => {
//   ipcRenderer.on("forward-item", () => history.forward());
// };

// // 后退
// const backMessage = () => {
//   ipcRenderer.on("back-item", () => history.back());
// };

// // 清除缓存
// const cleanCache = () => {
//   ipcRenderer.on("clean-cache", () => {
//     location.replace("/clientLogin");
//   });
// };

// // 界面更新
// const updateUI = () => {
//   ipcRenderer.on("update-ui", (_, args) => {
//     if (args) {
//       location.reload();
//     }
//   });
// };

// export {
//   sendMessageSync,
//   sendMessage,
//   refreshMessage,
//   forwardMessage,
//   backMessage,
//   updateUI,
//   cleanCache,
// };
