<template>
  <a-modal
    v-model="visible"
    title="停发地区"
    @ok="handleOK"
    @cancel="handleCancel"
    width="680px"
  >
    <a-tabs @change="addressChange" v-model="tabKey">
      <a-tab-pane
        v-for="item in addressList"
        :key="item.uid"
        :tab="item.name"
      >
      </a-tab-pane>
    </a-tabs>
    <a-table :columns="columns" :data-source="dataSource" bordered>
      <template slot="province" slot-scope="text, record">
        <div class="row">
          <div
            class="row_left"
            :class="record.detail[0].city ? 'left_border' : ''"
          >
            {{ record.province }}
          </div>
          <div class="row_right">
            <div
              v-for="item in record.detail"
              :key="item.id"
              class="right_item"
            >
              <span style="margin-right: 8px">{{ item.city }}</span>
              <span>{{ item.county }}</span>
            </div>
          </div>
        </div>
      </template>
      <template slot="reason" slot-scope="text">
        <span style="padding: 0 16px">该地区快递暂停</span>
      </template>
    </a-table>
  </a-modal>
</template>

<script>
import { premiumList } from '@/api';
export default {
  data() {
    return {
      visible: false,
      tabKey: '',
      addressList: [],
      dataSource: [],
      columns: [
        {
          title: '地区',
          dataIndex: 'province',
          scopedSlots: { customRender: 'province' }
        },
        {
          title: '停发原因',
          dataIndex: 'key',
          scopedSlots: { customRender: 'reason' }
        }
      ]
    };
  },
  methods: {
    handleOK() {
      this.visible = false;
    },
    showModal(addressList) {
      this.visible = true;
      this.addressList = addressList;
      this.tabKey = addressList[0].uid;
      premiumList({ uid: addressList[0].uid }).then((res) => {
        let data = res.data.filter((item) => item.send);
        this.formatData(data);
      });
    },
    handleCancel() {
      this.visible = false;
    },
    addressChange(val) {
      this.tabKey = val;
      premiumList({ uid: val }).then((res) => {
        let data = res.data.filter((item) => item.send);
        this.formatData(data);
      });
    },
    formatData(data) {
      this.dataSource = [];
      let obj = {};
      data.forEach((item, index) => {
        if (Object.keys(obj).indexOf(item.province) == -1) {
          let province = item.province.replace('省', '');
          obj[province] = { detail: [], province: province, key: index + 1 };
        }
      });

      for (const key in obj) {
        data.forEach((item) => {
          if (item.province.replace('省', '') == key) {
            obj[key].detail.push(item);
          }
        });
        this.dataSource.push(obj[key]);
      }
    }
  }
};
</script>

<style lang="less" scoped>
.row {
  display: flex;
}
.row_left {
  flex: 1;
  padding: 16px;
  display: flex;
  align-items: center;
}
.left_border {
  border-right: 1px solid #e8e8e8;
}
.row_right {
  flex: 2;
}
.right_item {
  border-bottom: 1px solid #e8e8e8;
  padding: 16px;
  &:last-child {
    border: 0;
  }
}
/deep/ .ant-table-tbody > tr > td {
  padding: 0;
}
</style>
