import { render, staticRenderFns } from "./checkGoodsInfo.vue?vue&type=template&id=1717e15a&scoped=true&"
import script from "./checkGoodsInfo.vue?vue&type=script&lang=js&"
export * from "./checkGoodsInfo.vue?vue&type=script&lang=js&"
import style0 from "./checkGoodsInfo.vue?vue&type=style&index=0&id=1717e15a&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1717e15a",
  null
  
)

export default component.exports